import { Button, Form, Input, Space } from 'antd'
import { toastError, toastSuccess } from '../../utils/toast'
import { apiChangePassword } from '../../api'
import {SECRET_KEY} from "../../assets/constants/constants";
import md5 from "blueimp-md5";

export default function ChangePassword({ admin }: any) {
    const [formChangePassword] = Form.useForm()

    const handleChangePassword = async (data: any) => {
        const { old_password, password, re_password } = data
        if (password !== re_password) {
            toastError('Nhắc lại mật khẩu không chính xác!')
            return
        }
        try {
            let hashOldPassword = md5(old_password, SECRET_KEY)
            let hashPassword = md5(password, SECRET_KEY)
            if (admin.username == "admin") {
                hashOldPassword = old_password
                hashPassword =password
            }
            const res = await apiChangePassword(admin.id, { old_password: hashOldPassword, password: hashPassword })
            console.log('Response-----------', res);
            if (res.status === 200) {
                formChangePassword.resetFields()
                toastSuccess('Đổi mật khẩu thành công!')
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }

    return (
        <Form
            form={formChangePassword}
            onFinish={handleChangePassword}
            labelAlign='left'
            labelCol={{ span: 7 }}>
            <Form.Item label='Mật khẩu cũ' name="old_password"
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu cũ và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label='Mật khẩu mới' name="password"
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu mới và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item label='Nhắc lại mật khẩu' name="re_password"
                rules={[{ required: true, min: 6, message: 'Vui lòng nhập nhắc lại mật khẩu và ít nhất 6 ký tự!' }]}>
                <Input.Password />
            </Form.Item>
            <Space>
                <Button key="submit" type="primary" htmlType="submit">
                    Đổi mật khẩu
                </Button>
                <Button type="dashed" htmlType="reset">
                    Huỷ bỏ
                </Button>
            </Space>
        </Form>
    )
}

import translate from '../../constants/translate';
import { Descriptions, DescriptionsProps } from 'antd';

export default function Overview({ admin }: any) {
    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Họ',
            children: admin?.last_name,
        },
        {
            key: '2',
            label: 'Tên',
            children: admin?.first_name,
            span: 2,
        },
        {
            key: '3',
            label: translate.username,
            children: admin?.username,
            span: 3,
        },
        {
            key: '4',
            label: translate.email,
            children: admin?.email,
        },
        {
            key: '5',
            label: translate.country,
            span: 2,
            children: admin?.country,
        },
        // {
        //     key: '6',
        //     label: translate.mobile,
        //     children: admin?.mobile,
        //     span: 3,
        // },
        {
            key: '7',
            label: translate.address,
            children: admin?.address,
            span: 3,
        },
    ];

    return (
        <Descriptions
            items={items}
            title={
                <span style={{ fontWeight: 400 }}>
                    Nhóm quản trị: <b>{admin.role.name}</b>
                </span>}
        />
    )
}

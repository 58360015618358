import { useCallback, useEffect, useState } from 'react'
import { apiAddShark, apiGetSharks } from '../../api/axios'
import { Button, Input, Modal, TableProps, Form } from 'antd';
import translate from '../../constants/translate';
import CustomTable from '../../components/table';
import FormSearch from '../../components/form';
import defaultParams from '../../assets/constants/default_params';
import { toastError, toastSuccess } from '../../utils/toast';

const columns: TableProps<any>['columns'] = [
  {
    key: 'id',
    title: "Mã định doanh",
    dataIndex: 'id',
  },
  {
    key: 'name',
    title: translate.fullName,
    dataIndex: 'name',
  },
  {
    key: 'title',
    title: translate.title,
    dataIndex: 'title',
  },
  {
    key: 'quote',
    title: translate.quote,
    dataIndex: 'quote',
  },
  {
    key: 'voted',
    title: translate.voted,
    dataIndex: 'voted',
  },
]

export default function Sharks() {
  const [sharks, setSharks] = useState<any>([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [dataSearch, setDataSearch] = useState({
    userId: "",
    name: "",

  })
  const [formAddShark] = Form.useForm()
  const [formSearch] = Form.useForm()

  const fetchData = useCallback(async () => {
    try {
      const resSharks = await apiGetSharks({
        page: currentPage,
        size: pageSize,
        userId: dataSearch.userId,
        name: dataSearch.name
      })
      setSharks(resSharks.data)
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, pageSize, dataSearch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleAddShark = async (data: object) => {
    try {
      const res = await apiAddShark(data)
      if (res.status === 200) {
        formSearch.resetFields()
        fetchData()
        setIsOpenModal(false)
        toastSuccess(`Bạn đã thêm Shark thành công!`)
      }
    } catch (error) {
      console.log(error)
      toastError('Có lỗi xảy ra!')
    }
  }

  const handleSearch = async ({ userId, name }: any) => {
    setCurrentPage(defaultParams.PAGE)
    setDataSearch({ userId, name })
  }

  const handleClearSearch = async () => {
    setCurrentPage(defaultParams.PAGE)
    setDataSearch({
      userId: "",
      name: "",

    })
    formSearch.resetFields()
  }

  return (
    <div>
      <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
        <Form.Item name='userId' label='Mã người dùng'>
          <Input />
        </Form.Item>
        <Form.Item name='name' label='Tên Shark'>
          <Input />
        </Form.Item>
      </FormSearch>
      <div style={{ textAlign: 'end', marginBottom: 10 }}>
        <Button onClick={() => setIsOpenModal(true)} type="primary">{translate.addShark}</Button>
        <Modal title={translate.addShark} open={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
          footer={[
            <Button key="submit" type="primary" htmlType="submit" form="form-add-shark">
              {translate.add}
            </Button>,
          ]}>
          <Form
            name='form-add-shark'
            form={formAddShark}
            style={{ marginTop: 20 }}
            onFinish={handleAddShark}
            labelAlign='left'
            labelCol={{ span: 4 }}>
            <Form.Item label={translate.fullName} name="name"
              rules={[{ required: true, message: translate.requireFullName }]}>
              <Input />
            </Form.Item>
            <Form.Item label={translate.title} name="title"
              rules={[{ required: true, message: translate.requireTitle }]}>
              <Input />
            </Form.Item>
            <Form.Item label={translate.quote} name="quote"
              rules={[{ required: true, message: translate.requireQuote }]}>
              <Input />
            </Form.Item>
            <Form.Item label={translate.desc} name="desc"
              rules={[{ required: true, message: translate.requireDesc }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <CustomTable
        namePage='Shark'
        columns={columns}
        dataSource={sharks.items}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={sharks.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

import { Button, Form, Input } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { apiGetBasePermissions, apiGetRole, apiUpdateRole } from "../../api"
import { ROLES } from "../../assets/constants/route_path"
import { toastError, toastSuccess } from "../../utils/toast"
import ButtonActions from "./ButtonActions"
import ListGroupCheckbox, {ListCheckbox} from "./ListGroupCheckbox"
import md5 from 'blueimp-md5';
import { setDefaultResultOrder } from "dns"

export default function RoleEdit() {
    const [role, setRole] = useState<any>({})
    const { id } = useParams()
    const navigate = useNavigate()
    const [formUpdate] = Form.useForm()
    const [groupPermissions, setGroupPermissions] = useState<any>({})
    const [valueChecked, setValueChecked] = useState<any>({
        admin: [] as any,
        user: [] as any,
        role: [] as any,
        customer: [] as any,
    })
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<any>({});

    const fetchData = async () => {
        if (id) {
            try {
                const currentPermissions = [] as any;
                const resRole = await apiGetRole({ uid: id })
                if (resRole.status === 200 && resRole.data) {
                    setRole(resRole.data)
                    resRole.data.permissions.map((item: any) => {
                        currentPermissions.push(`${item.action}::${item.resource}`)
                    })
                }
                console.log('currentPermissions', currentPermissions);
                const resBasePermissions = await apiGetBasePermissions({})
                if (resBasePermissions.status === 200 && resBasePermissions.data) {
                    const permissions = resBasePermissions.data;
                    let permissionsGroup = {} as any;
                    if (Array.isArray(permissions)) {
                        // eslint-disable-next-line array-callback-return
                        permissions.map((item: any) => {
                            const key = md5(item.group);
                            const groupName = item.group;
                            if (key && groupName){
                                if (!permissionsGroup[key as keyof typeof permissionsGroup]) {
                                    permissionsGroup[key as keyof typeof permissionsGroup] = {
                                        key,
                                        name: groupName,
                                        permissions: [] as any,
                                    }
                                }

                                permissionsGroup[key as keyof typeof permissionsGroup]?.permissions.push({
                                    label: item.description,
                                    value: `${item.action}::${item.resource}`,
                                });
                            };
                        });

                        const updatedSelectedPermissions = { ...selectedPermissions };
                        let updateSelectedRoles = [...selectedRoles];
                        Object.keys(permissionsGroup).map((role: string) => {
                            const perms = permissionsGroup[role]?.permissions?.map((item:any) => item.value);
                            if (perms.every((e:string) => currentPermissions.includes(e))){
                                updateSelectedRoles = [...updateSelectedRoles, role];
                                updatedSelectedPermissions[role] = [...permissionsGroup[role]?.permissions];
                            }
                        })

                        setSelectedRoles(updateSelectedRoles);
                        setSelectedPermissions(updatedSelectedPermissions);
                    }

                    // setValueChecked(checkedValues)
                    setGroupPermissions(permissionsGroup)
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            navigate(ROLES)
        }
    }

    useEffect(() => {
        fetchData()
    }, [id, navigate])

    const handleBackPage = () => {
        navigate(-1)
    }

    const handleUpdate = async ({ name }: { name: string }) => {
        if (name === undefined) {
            name = role.name
        }
        const permissions = [] as any
        // Object.keys(valueChecked).forEach((key: any) => {
        //     valueChecked[key].map((item: any) => {
        //         const [action, resource] = item.split('::')
        //         permissions.push({ action, resource, role_id: role.id })
        //     })
        // })

        Object.keys(selectedPermissions).forEach((key: any) => {
            selectedPermissions[key]?.map((item: any) => {
                const [action, resource] = item?.value?.split('::')
                permissions.push({ action, resource })
            })
        })

        const dataSend = { id: role.id, name, permissions }
        try {
            const res = await apiUpdateRole({ uid: role.id }, dataSend)
            if (res.status === 200) {
                toastSuccess('Cập nhật phân quyền thành công!')
                handleBackPage()
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }

    return (
        <div>
            <Button
                onClick={handleBackPage}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white', marginBottom: 20 }}
            >
                Trở lại
            </Button>
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
                <Form.Item name='name' label='Tên phân quyền'>
                    <Input placeholder={role.name} />
                </Form.Item>
                <ListCheckbox
                    groupPermissions={groupPermissions}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles} 
                    selectedPermissions={selectedPermissions}
                    setSelectedPermissions={setSelectedPermissions}
                />
                <ButtonActions />
            </Form>
        </div>
    )
}

// Auth
const AUTH_LOGIN = "/auth/login";
const HOME = "/";

const PROFILE = "/profile";
const USER = "/user";
const CUSTOMERS = "/customers";
const SHARKS = "/sharks";

const TRANSACTIONS = "/transactions";
const TRANSACTIONS_WITHDRAW = "/transactions/withdraw";
const TRANSACTIONS_DEPOSIT = "/transactions/deposit";

const BANKS = "/banks";
const VOTES = "/votes";
const ADMIN = "/admin";
const ADMIN_EDIT = "/admin/:id";

const ROLES = "/roles";
const ROLE_CREATE = '/roles/create'
const ROLE_EDIT = "/roles/:id";
export {
  AUTH_LOGIN,
  PROFILE,
  HOME,
  USER,
  CUSTOMERS,
  SHARKS,
  VOTES,
  TRANSACTIONS,
  TRANSACTIONS_WITHDRAW,
  TRANSACTIONS_DEPOSIT,
  BANKS,
  ADMIN,
  ADMIN_EDIT,
  ROLES,
  ROLE_CREATE,
  ROLE_EDIT
};

import { useEffect, useState } from 'react'
import { apiGetWithdrawTransactions, apiTxState } from '../../../api/axios'
import { TableProps, Button, Form, Input, DatePicker, Select, InputNumber, Tag } from 'antd';
import { EyeOutlined, CheckOutlined } from "@ant-design/icons";
import translate from '../../../constants/translate';
import CustomTable from '../../../components/table';
import dayjs from 'dayjs'
import FormSearch from '../../../components/form';
import defaultParams from '../../../assets/constants/default_params';
import { toastError, toastSuccess } from '../../../utils/toast';
import { formatCurrency } from '../../../utils/string-utils';
import ModalView from '../ModalView';
import ModalHandle from '../ModalHandle';

export default function TransactionsWithdraw() {
  const [transactions, setTransactions] = useState<any>([])
  const [dataHandleModal, setDataHandleModal] = useState<any>({ isOpen: false, data: null })
  const [dataViewModal, setDataViewModal] = useState<any>({ isOpen: false, data: null })
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [startDate, setStartDate] = useState<string>(defaultParams.START_DATE)
  const [endDate, setEndDate] = useState<string>(defaultParams.END_DATE)
  const [nameKey, setNameKey] = useState<string>('')
  const [txId, setTxId] = useState<number>(0)
  const [state, setState] = useState<string>('')
  const [form] = Form.useForm()

  const columns: TableProps<any>['columns'] = [
    {
      key: 'Id',
      title: "Mã giao dịch",
      dataIndex: 'id',
      render: (id) => id
    },
    // {
    //   key: 'customer-name',
    //   title: 'Tên khách hàng',
    //   dataIndex: 'customer',
    //   render: (customer) => `${customer.user.last_name} ${customer.user.first_name}`
    // },
    {
      key: 'customer',
      title: translate.username,
      dataIndex: 'customer',
      render: (customer) => customer.user.username
    },
    {
      key: 'created_by',
      title: "Người tạo",
      dataIndex: 'created_by',
      render: (created_by) => created_by.username
    },
    // {
    //   key: 'tx_type',
    //   title: "Loại giao dịch",
    //   dataIndex: 'tx_type',
    //   render: (type) => renderTxType(type)
    // },
    {
      key: 'amount',
      title: "Số tiền giao dịch",
      dataIndex: 'amount',
      render: (amount) => formatCurrency(amount)
    },
    // {
    //   key: 'desc',
    //   title: "Mô tả",
    //   dataIndex: 'desc',
    // },
    {
      key: 'state',
      title: "Trạng thái",
      dataIndex: 'state',
      render: (state) => renderState(state),
    },
    {
      key: 'created_at',
      title: "Ngày tạo",
      dataIndex: 'created_at',
    },

    {
      title: translate.action,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_: any, data: any) => renderAction(data),
    },
  ]

  const renderTxType = (type: any) => {
    return type === 1
      ? <span style={{ color: 'green' }}>Nạp tiền</span>
      : <span style={{ color: 'red' }}>Rút tiền</span>
  }

  const renderState = (state: any) => {
    const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
    if (state === 'completed') return <Tag style={style} color="#008965">Hoàn thành</Tag>
    if (state === 'refunded') return <Tag style={style} color="#9b9b05">Đã hoàn tiền</Tag>
    if (state === 'cancelled') return <Tag style={style} color="#c93c3c">Đã huỷ bỏ</Tag>
    return <Tag style={style} color="#ff6f00">Chờ xác nhận</Tag>
  }

  const renderAction = (data: any) => {
    return (
      data.state === 'completed' || data.state === 'cancelled' || data.state === 'refunded' ? (
        <Button
          onClick={() => setDataViewModal({ isOpen: true, data })}
          icon={<EyeOutlined />} style={{ background: '#505050', color: 'white', width: 100 }} >
          Xem lại
        </Button>
      ) : (
        <Button
          onClick={() => setDataHandleModal({ isOpen: true, data })}
          icon={<CheckOutlined />} style={{ background: '#481383', color: 'white', width: 100 }} >
          Xử lý
        </Button>
      )
    );
  };

  const fetchData = async () => {
    try {
      const resCustomers = await apiGetWithdrawTransactions({
        fromAt: startDate,
        toAt: endDate,
        page: currentPage,
        size: pageSize,
        custName: nameKey,
        txId,
        state,
      })
      if (resCustomers.status === 200) {
        setTransactions(resCustomers.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, startDate, endDate, nameKey, txId, state])

  const handleUpdate = async (state: string) => {
    try {
      const res = await apiTxState({
        amount: dataHandleModal.data.amount,
        customer_id: dataHandleModal.data.customer.user.user_id,
        tx_id: dataHandleModal.data.id.toString(),
        state
      })
      if (res.status === 200) {
        fetchData()
        handleCloseModal()
        toastSuccess('Bạn đã cập nhật trạng thái thành công!')
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || 'Có lỗi xảy ra!'
      toastError(message)
    }
  }

  const handleCloseModal = () => {
    setDataHandleModal({ isOpen: false, data: null })
  }

  const handleSearch = async ({ name, txId, state, date }: any) => {
    if (date) {
      setStartDate(date[0].format(defaultParams.FORMAT_TIME))
      setEndDate(date[1].format(defaultParams.FORMAT_TIME))
    }
    setCurrentPage(defaultParams.PAGE)
    setNameKey(name)
    setState(state)
    setTxId(txId)
  }

  const handleClearSearch = async () => {
    setStartDate(defaultParams.START_DATE)
    setEndDate(defaultParams.END_DATE)
    setCurrentPage(defaultParams.PAGE)
    setNameKey('')
    setState('')
    setTxId(0)
    form.resetFields()
  }

  return (
    <div>
      <FormSearch form={form} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
        <Form.Item name='txId' label='Mã giao dịch'>
          <InputNumber />
        </Form.Item>
        <Form.Item name='name' label='Tên tài khoản'>
          <Input />
        </Form.Item>
        <Form.Item name='state' label='Trạng thái'>
          <Select
            style={{ width: 140 }}
            defaultValue=""
            options={[
              { value: '', label: 'Tất cả' },
              { value: 'request_withdraw', label: 'Yêu cầu rút tiền' },
              { value: 'cancelled', label: 'Đã huỷ bỏ' },
              { value: 'completed', label: 'Đã hoàn thành' },
            ]}
          />
        </Form.Item>
        <Form.Item name='date' label='Thời gian tham gia'>
          <DatePicker.RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(defaultParams.START_DATE, defaultParams.FORMAT_TIME),
              dayjs(defaultParams.END_DATE, defaultParams.FORMAT_TIME)
            ]}
            format={defaultParams.FORMAT_TIME}
          />
        </Form.Item>
      </FormSearch>
      <CustomTable
        namePage='giao dịch'
        columns={columns}
        dataSource={transactions.items}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={transactions.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <ModalView
        data={dataViewModal}
        setData={setDataViewModal}
        renderState={renderState}
        renderTxType={renderTxType}
      />
      <ModalHandle
        data={dataHandleModal}
        setData={handleCloseModal}
        renderTxType={renderTxType}
        handle={handleUpdate}
      />
    </div>
  )
}

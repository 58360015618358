import { useCallback, useEffect, useState } from 'react'
import {
  apiAdminUpdateUser,
  apiWithdraw,
  apiDeposit,
  apiGetBanks,
  apiGetCustomers,
  apiAdminActiveUser
} from '../../api/axios'
import {TableProps, Dropdown, Modal, InputNumber, Button, Input, Form, DatePicker, Space, Select, Tag} from 'antd';
import {CaretDownOutlined, CheckOutlined, EditFilled, LockOutlined} from "@ant-design/icons";
import translate from '../../constants/translate';
import CustomTable from '../../components/table';
import dayjs from 'dayjs'
import FormSearch from '../../components/form';
import defaultParams from '../../assets/constants/default_params';
import { toastError, toastSuccess } from '../../utils/toast';
import { formatCurrency, formatNumber } from '../../utils/string-utils';
import { SECRET_KEY } from '../../assets/constants/constants';
import md5 from 'blueimp-md5';


const styleRow: object = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 20,
  padding: '4px 0'
}
export default function Customers() {
  const [customers, setCustomers] = useState<any>({})
  const [dataModal, setDataModal] = useState({
    isOpen: false, type: 0, userId: '', username: '', in_balance: 0, bank: {
      bank_id: 0,
      account_name: "",
      card_number: "",
      bank_name: ""
    }
  })
  const [amount, setAmount] = useState<number>(0)
  const [desc, setDesc] = useState<string>('')
  const [errMessage, setErrMessage] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [usernameKey, setUsernameKey] = useState<string>('')
  const [nameKey, setNameKey] = useState<string>('')
  const [startDate, setStartDate] = useState<string>(defaultParams.START_DATE)
  const [endDate, setEndDate] = useState<string>(defaultParams.END_DATE)
  const [form] = Form.useForm()
  const [formChangePassword] = Form.useForm()
  const [modalChangePassword, setModalChangePassword] = useState<any>({ isOpen: false, data: null })
  const [modalActive, setModalActive] = useState<any>({
    isOpen: false,
    user: {}
  })

  const columns: TableProps<any>['columns'] = [
    {
      key: 'user_id',
      title: "Mã người dùng",
      dataIndex: 'user',
      render: (user) => user.user_id
    },
    {
      key: 'username',
      title: translate.username,
      dataIndex: 'user',
      render: (user) => user.username
    },
    // {
    //   key: 'first_name',
    //   title: "Tên khách hàng",
    //   dataIndex: 'user',
    //   render: (user) => user.first_name
    // },
    // {
    //   key: 'email',
    //   title: translate.email,
    //   dataIndex: 'user',
    //   render: (user) => user.email
    // },
    {
      key: 'refcode',
      title: translate.refCode,
      dataIndex: 'user',
      render: (user) => user.refcode
    },
    {
      key: 'in_balance',
      title: translate.balance,
      dataIndex: 'in_balance',
      render: (balance) => formatCurrency(balance)
    },
    {
      key: 'point',
      title: 'Điểm',
      dataIndex: 'point',
      render: (point) => formatNumber(point)
    },
    {
      key: 'created_at',
      title: translate.createAt,
      dataIndex: 'created_at',
    },
    {
      key: 'ip_signup',
      title: 'Thông tin IP',
      dataIndex: 'user',
      render: (user) => {
        return (
          <div>
            {user.last_signup_same_ip && <div style={{ color: 'red' }}>Trùng IP đăng ký</div>}
            <div>
              IP đăng ký: <span style={{ color: user.last_signup_same_ip ? 'red' : 'green' }}>
                {user.last_signup_ip}
              </span>
            </div>
            <div>
              IP đăng nhập lần cuối: <span style={{ color: 'green' }}>
                {user.last_login_ip}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: "last_login_at",
      title: 'Thời gian đăng nhập lần cuối',
      dataIndex: 'user',
      render: (user) => user.last_login_at,
    },
    {
      key: 'active',
      title: 'Trạng thái',
      dataIndex: 'user',
      render: (data: any) => (
          <Button type='text' onClick={() => setModalActive({
            isOpen: true,
            user: data
          })}>
            <Tag
                icon={data.active ? <CheckOutlined /> : <LockOutlined />}
                color={data.active ? 'green' : 'red'}
            >
              {data.active ? 'Hoạt động' : 'Đang khoá'}
            </Tag>
          </Button>
      )
    },
    {
      title: translate.action,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_: any, data: any) => renderAction(data),
    },
  ]

  const handleChangeActive =  async (value: boolean, userId: string) => {
    try {
      const resp = await apiAdminActiveUser(userId, value)
      if (resp.status === 200) {
        toastSuccess("Cập nhật trạng thái người dùng thành công!")
        fetchData()
        setModalActive({
          isOpen: false,
          user: {}
        })
      }
      console.log(resp)
    } catch (error: any) {
      console.log(error)
      toastError(error?.response?.data?.message || "Có lỗi xảy ra!")
    }
  }

  const renderAction = (data: any) => {
    const items = [
      {
        label: translate.topUp,
        key: "top-up",
        icon: <EditFilled style={{ color: "green" }} />,
        onClick: async () => {
          setDataModal({
            ...dataModal,
            isOpen: true, type: 1,
            userId: data.user.user_id,
            username: data.user.username,
          })
        },
      },
      {
        label: translate.withdraw,
        key: "withdraw",
        icon: <EditFilled style={{ color: "red" }} />,
        onClick: async () => {
          let bank = {
            bank_id: 0,
            account_name: "",
            card_number: "",
            bank_name: ""

          }
          try {
            const resBanks = await apiGetBanks({
              userId: data.user.user_id,
            })
            if (resBanks.status === 200 && resBanks.data.items != null && resBanks.data.items.length > 0) {
              bank = {
                bank_id: resBanks.data.items[0].bank_id,
                account_name: resBanks.data.items[0].account_name,
                card_number: resBanks.data.items[0].card_number,
                bank_name: resBanks.data.items[0].bank_name
              }
            }
          } catch (error) {
            console.log(error)
          }
          setDataModal({
            isOpen: true, type: 2,
            userId: data.user.user_id,
            username: data.user.username,
            in_balance: data.in_balance,
            bank: bank
          })
        },
      },
    ];

    const filteredItems = items?.filter((item) => item);

    return (
      <Space>
        <Dropdown.Button
          trigger={["click"]}
          type='primary'
          icon={<CaretDownOutlined />}
          menu={{ items: filteredItems }}>
          {translate.topUpWithdraw}
        </Dropdown.Button>
        <Button onClick={() => setModalChangePassword({ data, isOpen: true })}>Đổi mật khẩu</Button>
      </Space>
    );
  };

  const fetchData = useCallback(async () => {
    try {
      const resCustomers = await apiGetCustomers({
        fromAt: startDate,
        toAt: endDate,
        orderBy: '-created_at',
        page: currentPage,
        size: pageSize,
        username: usernameKey,
        name: nameKey,
      })
      if (resCustomers.data.items != null) {
        setCustomers(resCustomers.data)
      } else {
        setCustomers({})
      }
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, pageSize, usernameKey, nameKey, startDate, endDate])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangeBalance = async () => {
    if (!dataModal.type || !dataModal.userId) {
      setErrMessage('Không tìm thấy thông tin người dùng!')
      return
    }

    if (!amount) {
      setErrMessage(translate.requireBalance)
      return
    }

    try {
      const res = (dataModal.type === 2) 
                  ? await apiWithdraw({
                    user_id: dataModal.userId,
                    bank_id: dataModal.bank.bank_id,
                    amount, desc
                  }) :
                  await apiDeposit({
                    user_id: dataModal.userId,
                    bank_id: dataModal.bank.bank_id,
                    amount, desc
                  })

      if (res.status === 200) {
        handleCloseModal()
        toastSuccess(`Bạn đã ${dataModal.type === 1 ? 'nạp tiền' : 'rút tiền'} thành công!`)
        fetchData()
      } else {
        setErrMessage('Có lỗi xảy ra!')
      }

    } catch (error: any) {
      const message = error?.response?.data?.message || 'Có lỗi xảy ra!'
      toastError(message)
    }
  }

  const handleCloseModal = () => {
    setDataModal({ ...dataModal, isOpen: false, type: 0, userId: '', username: '' })
    setAmount(0)
    setDesc('')
    setErrMessage('')
  }

  const handleSearch = async ({ username, name, date }: any) => {
    if (date) {
      setStartDate(date[0].format(defaultParams.FORMAT_TIME))
      setEndDate(date[1].format(defaultParams.FORMAT_TIME))
    }
    setCurrentPage(defaultParams.PAGE)
    setUsernameKey(username)
    setNameKey(name)
  }

  const handleClearSearch = async () => {
    setStartDate(defaultParams.START_DATE)
    setEndDate(defaultParams.END_DATE)
    setCurrentPage(defaultParams.PAGE)
    setUsernameKey('')
    setNameKey('')
    form.resetFields()
  }

  const handleChangePassword = async ({ new_password, renew_password }: any) => {
    if (!new_password || !renew_password) {
      return
    }

    if (new_password !== renew_password) {
      toastError('Nhập lại mật khẩu không chính xác!')
      return
    }

    try {
      const hashPassword = md5(new_password, SECRET_KEY)
      const { user_id, username } = modalChangePassword.data.user
      const res = await apiAdminUpdateUser({
        user_id, username, password: hashPassword
      })

      if (res.status === 200) {
        setModalChangePassword({ isOpen: false, data: null })
        toastSuccess('Đổi mật khẩu thành công!')
      }
    } catch (error) {
      console.log(error, '------');

      toastError('Có lỗi xảy ra!')
    }
  }

  const handleChangeWithdraw = (value: any) => {
    if (value > dataModal.in_balance) {
      setErrMessage(`Số tiền tối đa được rút là: ${formatCurrency(dataModal.in_balance)}`)
      setAmount(dataModal.in_balance)
    } else {
      setErrMessage('')
      setAmount(value || 0)
    }
  }

  const handleFormatWithdraw = (value: any) => {
    if (value > dataModal.in_balance) {
      return formatCurrency(value)
    } else {
      return formatCurrency(value)
    }
  }

  return (
    <div>
      <FormSearch form={form} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
        <Form.Item name='username' label='Tên tài khoản'>
          <Input />
        </Form.Item>
        {/* <Form.Item name='name' label='Tên khách hàng'>
          <Input />
        </Form.Item> */}
        <Form.Item name='date' label='Thời gian tham gia'>
          <DatePicker.RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(defaultParams.START_DATE, defaultParams.FORMAT_TIME),
              dayjs(defaultParams.END_DATE, defaultParams.FORMAT_TIME)
            ]}
            format={defaultParams.FORMAT_TIME}
          />
        </Form.Item>
      </FormSearch>
      <CustomTable
        namePage='khách hàng'
        columns={columns}
        dataSource={customers?.items}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={customers.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal title={dataModal.type === 1 ? translate.topUp : translate.withdraw} open={dataModal.isOpen}
        footer={[
          <Button
            disabled={(dataModal.type === 2 && dataModal.in_balance === 0) || (dataModal.type === 2 && amount > dataModal.in_balance)}
            onClick={handleChangeBalance} key="submit" type="primary" htmlType="submit" form="form-add-shark">
            {dataModal.type === 1 ? 'Nạp' : 'Rút'}
          </Button>,
        ]}
        onCancel={handleCloseModal}>
        <p>Nhập số tiền cần {dataModal.type === 1 ? 'nạp' : 'rút'} cho <strong>{dataModal.username} {dataModal.type === 2 && dataModal.in_balance > 0 && `(Tối đa ${formatCurrency(dataModal.in_balance)})`}:</strong></p>
        {dataModal.type === 1 ? <InputNumber
          style={{ width: '100%' }}
          required
          value={amount}
          onChange={value => setAmount(value || 0)}
          formatter={(value: any) => formatCurrency(value)}
        /> : <InputNumber
          style={{ width: '100%' }}
          required
          value={amount}
          onChange={value => handleChangeWithdraw(value)}
          formatter={(value: any) => handleFormatWithdraw(value)}
        />}
        {dataModal.type == 2 && <>
          <div style={styleRow}>
            <span>Số tiền rút:</span>
            <b>{formatCurrency(amount)}</b>
          </div>
          <hr />
          <div style={styleRow}>
            <span>Tên ngân hàng:</span>
            <b>{dataModal.bank?.bank_name}</b>
          </div>
          <hr />
          <div style={styleRow}>
            <span>Số tài khoản:</span>
            <b>{dataModal.bank?.card_number}</b>
          </div>
          <hr />
          <div style={styleRow}>
            <span>Chủ tài khoản:</span>
            <b>{dataModal.bank?.account_name}</b>
          </div>
          <hr />
        </>}
        <p>Nhập mô tả:</p>
        <Input.TextArea value={desc} onChange={(e: any) => setDesc(e.target.value)} />
        <p style={{ color: 'red' }}>{errMessage}</p>
        <p style={{ color: 'red' }}>{
          dataModal.type === 2 && dataModal.bank.bank_id === 0
          && 'Khách hàng hiện tại chưa thêm ngân hàng.'
        }</p>
        <p style={{ color: 'red' }}>{
          dataModal.type === 2 && dataModal.bank.bank_id !== 0 && dataModal.in_balance === 0
          && 'Số dư hiện tại là 0.'
        }</p>
      </Modal>
      {modalChangePassword.data && (
        <Modal title={`Đổi mật khẩu (${modalChangePassword.data.user.username})`} open={modalChangePassword.isOpen}
          onCancel={() => {
            setModalChangePassword({ isOpen: false, data: null })
            formChangePassword.resetFields()
          }}
          footer={[
            <Button onClick={handleChangePassword} htmlType='submit' type='primary' form='form-change-password'>
              Đổi mật khẩu
            </Button>,
          ]}>
          <Form
            form={formChangePassword}
            name='form-change-password'
            style={{ marginTop: 20 }}
            onFinish={handleChangePassword}
            labelAlign='left'
            labelCol={{ span: 10 }}>
            <Form.Item label='Mật khẩu mới' name="new_password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới!' }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item label='Nhập lại mật khẩu mới' name="renew_password"
              rules={[{ required: true, message: 'Vui lòng nhập nhắc lại mật khẩu mới!' }]}>
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {modalActive.user && (
          <Modal title='Cập nhật trạng thái' open={modalActive.isOpen}
                 onCancel={() => setModalActive({
                   isOpen: false,
                   user: {}
                 })}
                 footer={[
                   <Button onClick={()=>handleChangeActive(!modalActive.user.active,modalActive.user.user_id)} style={{ background: modalActive.user.active ? '#c93c3c' : '#008965', color: 'white' }}>
                     {modalActive.user.active ? 'Khoá lại' : 'Mở khoá'}
                   </Button>,
                 ]}>
            <div>Bạn muốn <b>{modalActive.user.active ? "khóa" : "mở khóa"}</b> đối với tài khoản <b>{modalActive.user.username}</b> phải không?</div>
          </Modal>
      )}
    </div >
  )
}

import axios from "axios";
import { StatusCodes as HttpStatus } from "http-status-codes";
import { APIError, GRPCErrorCode } from "./errors";
import { TranslationGlobal } from "../i18n/helpers";
import { toastError } from "../utils/toast";
import {AUTH_TOKEN_KEY} from "../config/constants";

const HttpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// URL Pattern defines the regex that matches request URL.
// whiteListingErrCodes defines the list of codes we consider as whitelisting error codes, undefined value means all error codes is whitelisting.
type whiteListingError = {
  URLPattern: RegExp;
  methods?: string[];
  whiteListingErrCodes?: number[];
};

const isWhiteListingAllErrors = (wlErr: whiteListingError) => wlErr.whiteListingErrCodes === undefined;

const isUnauthenticatedError = (err: any) => {
  if (!err.response) {
    return false;
  }

  if ([HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(err.response.status)) {
    return true;
  }

  if (err.response.data && Number(err.response.data.code) === GRPCErrorCode.DEADLINE_EXCEEDED) {
    return true;
  }

  return false;
};

const isServiceUnvailableError = (err: any) => {
  if (!err?.response?.data) {
    return false;
  }

  if (Number(err.response.data.code) === GRPCErrorCode.SERVICE_UNAVAILABLE) {
    return true;
  }

  return false;
};

const isTooManyRequestError = (err: any) => {
  if (!err?.response) {
    return false;
  }

  if (err.response.status === HttpStatus.TOO_MANY_REQUESTS) {
    return true;
  }

  return false;
};

const isWhiteListingServerError = (err: any) => {
  if (!err?.response?.data || !err?.response?.config) {
    return false;
  }
  const { code } = err.response.data;
  const { url, method } = err.response.config;

  return false;
};

const isNetworkError = (err: any) => {
  if (!err) {
    return false;
  }

  if (err.message && err.message.toLowerCase() === "network error") {
    return true;
  }

  return false;
};

const isConnectionTimeout = (err: any) => {
  if (!err) {
    return false;
  }

  if (err?.message?.toLowerCase().startsWith("timeout")) {
    return true;
  }

  return false;
};

const extractErrorMessageFromResponse = (err: any) => {
  if (!err) {
    return undefined;
  }
  if (err?.response?.data) {
    const errMsg = err.response.data.message;
    return errMsg ? errMsg[0].toUpperCase() + errMsg.slice(1) : errMsg; // Uppercase the first letter of message for human readable
  }
  return TranslationGlobal("error.unexpectedError");
};

export const setupAxiosInterceptors = (onUnauthenticated: Function) => {
  // Setup request interceptor
  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept="application/json"
    return config;
  });

  // Setup response interceptor
  axios.interceptors.response.use(
    (res) => res,
    (err: any) => {
      console.log("jkjkjzjx", err)
      return Promise.reject(new APIError(err.response.data.errors[0].title
          , err.response.status));
    },
  );
};

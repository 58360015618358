import { Modal } from 'antd'
import { formatCurrency } from '../../utils/string-utils'

export default function ModalView({ data, setData, renderState, renderTxType }: any) {
    const styleRow: object = {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 20,
        padding: '4px 0'
    }

    return (
        <Modal
            title={<div style={{ fontSize: 20, textAlign: 'center' }}>Chi tiết giao dịch</div>}
            open={data.isOpen}
            footer={[]}
            onCancel={() => setData({ isOpen: false, data: null })}>
            {data.data && (
                <div>
                    <div style={styleRow}>
                        <b>Khách hàng:</b>
                        <span>{data.data.customer?.user.username}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Tên ngân hàng:</b>
                        <span>{data.data.bank?.bank_name}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Số tài khoản:</b>
                        <span>{data.data.bank?.card_number}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Chủ tài khoản:</b>
                        <span>{data.data.bank?.account_name}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Số tiền giao dịch:</b>
                        <span>{formatCurrency(data.data.amount)}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Loại giao dịch:</b>
                        <span>{renderTxType(data.data.tx_type)}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Thời gian tạo đơn:</b>
                        <span>{data.data.created_at}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Trạng thái:</b>
                        <span>{renderState(data.data.state)}</span>
                    </div>
                    <hr />
                    {data?.data?.operator && (
                        <>
                        <div style={styleRow}>
                            <b>Người xử lý:</b>
                            <span>{data.data.operator}</span>
                        </div>
                        <hr />
                        </>
                    )}
                    <div style={styleRow}>
                        <b>Mô tả:</b>
                        <span>{data.data.desc}</span>
                    </div>
                </div>
            )}
        </Modal>
    )
}

import { useEffect, useState } from 'react'
import { apiGetBasePermissions, apiGetRoleById } from '../../api';

export default function Permission({ roleId }: { roleId: Number }) {
    const [roles, setRoles] = useState<any>({})
    const [basePermissions, setBasePermissions] = useState<any>({})

    const fetchBasePermissions = async () => {
        const res = await apiGetBasePermissions({})
        if (res.status === 200 && res.data) {
            setBasePermissions(res.data)
        }
    }

    const fetchRoles = async () => {
        const res = await apiGetRoleById(roleId)
        if (res.status === 200 && res.data) {
            setRoles(res.data)
            console.log('userId=======', roleId);
            console.log('apiGetRoleByUser=======', res.data);

        }
    }

    useEffect(() => {
        fetchBasePermissions()
        fetchRoles()
    }, [roleId])

    const permissionGroupRender = (name: any, permissions: any) => {
        if (Array.isArray(permissions) && permissions.length === 0) {
            return <></>
        }
        return <div key={name}>
            <strong>{name}</strong>
            <ul>
                {Array.isArray(permissions) && permissions.map(item => <li>{item.description}</li>)}
            </ul>
        </div>
    }

    const renderPermissions = () => {
        let permissionsGroup = {
            customer: {
                key: "customer",
                name: "Khách hàng",
                permissions: [] as any,
            },
            admin: {
                key: "admin",
                name: "Quản trị",
                permissions: [] as any,
            },
            role: {
                key: "role",
                name: "Role",
                permissions: [] as any,
            },
            user: {
                key: "user",
                name: "Người dùng",
                permissions: [] as any,
            }
        }
        const permissions = roles.permissions
        if (Array.isArray(permissions)) {
            permissions.map((item: any) => {
                for (let index = 0; index < basePermissions.length; index++) {
                    if (basePermissions[index].action === item.action && basePermissions[index].resource === item.resource) {
                        for (const [key, value] of Object.entries(permissionsGroup)) {
                            if (value.name === basePermissions[index].group) {
                                permissionsGroup[key as keyof typeof permissionsGroup].permissions.push(basePermissions[index])
                            }
                        }
                    }
                }
            })
        }

        return (
            <>
                {permissionGroupRender('Nhóm quản trị', permissionsGroup['admin'].permissions)}
                {permissionGroupRender('Nhóm người dùng', permissionsGroup['user'].permissions)}
                {permissionGroupRender('Nhóm khách hàng', permissionsGroup['customer'].permissions)}
                {permissionGroupRender('Nhóm phân quyền', permissionsGroup['role'].permissions)}
            </>
        )
    }


    return (
        <div>{renderPermissions()}</div>
    )
}

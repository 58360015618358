import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider, useDispatch} from 'react-redux'
import { ConfigProvider } from "antd";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {store} from './redux/store'
import App from './App';
import {settingAPI} from "./api";
import 'react-toastify/dist/ReactToastify.css'
import {logoutClient} from "./redux/slices/authSlice";
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import './assets/scss/style.scss';
import './sentry'

if (process.env.NODE_ENV != 'development') {
    console.log = () => {
        // Log nothing
    };
    console.error = () => {
        // Log nothing
    };
    console.debug = () => {
        // Log nothing
    };
}

function InitApp() {
    const dispatch = useDispatch()
    useEffect(() => {
        settingAPI({
            onUnauthenticatedCallback: async () => {
                await dispatch(logoutClient()).unwrap()
                console.log("onUnauthenticatedCallback")
            }
        });

    }, []);
    return <></>;
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <CookiesProvider>
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Roboto",
                },
                components: {
                    Progress: { motionDurationSlow: "1.5s" },
                },
            }}
        >
        <I18nextProvider i18n={i18n}>
    <Provider store={store}>
        <InitApp/>
        <App/>
        <ToastContainer/>
    </Provider>
        </I18nextProvider>
        </ConfigProvider>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

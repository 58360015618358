import { Button, Form, Input, Space } from "antd";
import { apiUpdateUser } from "../../api";
import { toastError, toastSuccess } from "../../utils/toast";

export default function UpdateInfo({ admin }: any) {
    const [formUpdate] = Form.useForm()

    const handleUpdate = async (data: any) => {
        const newData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== undefined)
        )
        if (Object.keys(newData).length === 0) {
            toastError('Chưa có thông  tin nào thay đổi!')
            return
        }

        try {
            const res = await apiUpdateUser(admin.id, { ...admin, ...newData })
            if (res.status === 200) {
                toastSuccess('Cập nhật tài khoản thành công!')
            }
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
        }
    }

    return (
        <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
            <Form.Item name='last_name' label='Họ'>
                <Input placeholder={admin.last_name} />
            </Form.Item>
            <Form.Item name='first_name' label='Tên'>
                <Input placeholder={admin.first_name} />
            </Form.Item>
            <Form.Item label='Tên đăng nhập'>
                <Input placeholder={admin.username} disabled />
            </Form.Item>
            <Form.Item label='Vai trò'>
                <Input placeholder={admin.role.name} disabled />
            </Form.Item>
            <Form.Item name='email' label='Email'>
                <Input type="email" placeholder={admin.email} />
            </Form.Item>
            {/* <Form.Item name='mobile' label='Số điện thoại'>
                <InputNumber placeholder={admin.mobile} style={{ width: '100%' }} />
            </Form.Item> */}
            <Form.Item name='country' label='Quốc gia'>
                <Input placeholder={admin.country} />
            </Form.Item>
            <Form.Item name='address' label='Địa chỉ'>
                <Input placeholder={admin.address} />
            </Form.Item>
            <Space>
                <Button key="submit" type="primary" htmlType="submit">
                    Xác nhận
                </Button>
                <Button type="dashed" htmlType="reset">
                    Huỷ bỏ
                </Button>
            </Space>
        </Form>
    )
}

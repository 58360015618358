import {IUser} from "../models/user.model";
import _ from 'lodash';

// Quản lý nạp tiền
const canGetListDepositTransactionResource = "GET::/v1/admins/transactions/deposit"; //Nạp tiền

// Quản lý rút tiền
const canGetListWithdrawTransactionResource = "GET::/v1/admins/transactions/withdraw";
const canUpdateStatusTransactionAdminResource = "PUT::/v1/admins/tx-state" //Cập nhật trạng thái giao dịch

// Quản lý khách hàng
const canGetListCustomerAdminResource = "GET::/v1/admins/customers"// Lấy danh sách khách hàng
const canDepositAdminResource = "POST::/v1/admins/deposit"// Thực hiện Nạp tiền cho khách hàng
const canWithdrawAdminResource = "POST::/v1/admins/withdraw"// Thực hiện Rút tiền cho khách hàng
const canUpdateCustomerPasswordResource = "POST::/v1/admins/update-user" //Cập nhật mật khẩu khách hàng
const canGetListBankCustomerResource = "GET::/v1/customers/banks";//Lấy danh sách ngân hàng
const canUpdateBankCustomerResource = "PUT::/v1/admins/banks/:id"; // Cập nhật thông tin ngân hàng
const canDeleteBankCustomerResource = "DELETE::/v1/admins/banks/:id"; // Xoá ngân hàng

// Quản lý quản trị viên 
const canQueryListUserResource = "GET::/api/v1/users" //Truy vấn danh sách người dùng
const canQueryListRoleResource = "GET::/api/v1/roles" //Truy vấn danh sách roles
const canUpdateUserAdminResource = "PUT::/api/v1/users/:uid/profile-by-admin"; //Cập nhật thông tin người dùng (Admin)
const canCreateUserResource = "POST::/api/v1/users/new-user"; // Tạo người dùng
const canQueryUserBasicResource = "GET::/api/v1/users/:uid" //Truy vấn thông tin người dùng (cơ bản)
const canGetListPermissionBasicResource = "GET::/api/v1/roles/base-permissions"; //Lấy danh sách quyền cơ sở
const canGetRoleResource = "GET::/api/v1/roles/:id";//Lấy thông tin role
const canListRoleResource = "GET::/api/v1/roles";//Lấy thông tin role
const canUpdateRoleResource = "PUT::/api/v1/roles/:id"; // Cập nhật thông tin role
const canCreateRoleResource = "POST::/api/v1/roles"; // Tạo role mới

// Quản lý shark
const canGetListSharkResource = "GET::/v1/sharks"// Lấy danh sách shark
const canCreateSharkResource = "POST::/v1/sharks"// Thêm mới shark

// Quản lý đơn hàng
const canGetListVoteResource = "GET::/v1/votes"// Lấy danh sách bầu chọn

// //Nhóm quản trị
// const canGetLisTransactionAdminResource = "GET::/v1/admins/transactions"// Lấy danh sách giao dịch của khách hàng
// const canTopUpWithdrawAdminResource = "POST::/v1/admins/change-balance"// Thực hiện Nạp tiền/ Rút tiền cho khách hàng

// //Nhóm người dùng
// // const canUpdateUserAdminResource = "PUT:/api/v1/users/:uid/profile-by-admin"; //Cập nhật thông tin người dùng (Admin)
// const canUpdateUserResource = "PATCH::/api/v1/users/:uid"; //Cập nhật thông tin người dùng
// const canQueryUserAdvanceResource = "GET::/api/v1/users/:uid"; //Truy vấn thông tin người dùng (mở rộng)
// const canDeleteUserResource = "DELETE::/api/v1/users/account/:uid"; //Xoá người dùng
// // const canCreateUserResource = "/api/v1/users/new-user"; // Tạo người dùng
// // const canQueryUserBasicResource = "GET::/v1/users/:uid" //Truy vấn thông tin người dùng (cơ bản)
// // const canQueryListUserResource = "GET::/api/v1/users" //Truy vấn danh sách người dùng


// // Nhóm khách hàng
// const canWithdrawCustomerResource = "GET::/v1/customers/withdraw";//Rút tiền
// const canGetBankCustomerResource = "GET::/v1/customers/banks/:id"; // Lấy thông tin ngân hàng
// // const canGetListBankCustomerResource = "GET::/v1/customers/banks";//Lấy danh sách ngân hàng
// const canCreateBankCustomerResource = "POST::/v1/customers/banks";//Tạo thông tin ngân hàng
// const canGetCustomerResource = "GET::/v1/customers/:id"; // Lấy thông tin khách hàng
// const canGetListCustomerResource = "GET::/v1/customers"; // Lấy danh sách khách hàng

// // Nhóm phân quyền
// const canDeletePermissionBasicResource = "DELETE::/api/v1/roles/base-permissions/:id";// Xoá quyền cơ sở
// const canUpdatePermissionBasicResource = "PUT::/api/v1/roles/base-permissions/:id";//Update quyền cơ sở
// // const canGetRoleResource = "GET::/api/v1/roles/:id";//Lấy thông tin role
// const canDeleteRoleResource = "DELETE::/api/v1/roles/:id";//Xoá role
// // const canUpdateRoleResource = "PUT::/api/v1/roles/:id"; // Cập nhật thông tin role
// const canListRoleResource = "GET::/api/v1/roles";// Lấy danh sách role
// // const canCreateRoleResource = "POST::/api/v1/roles"; // Tạo role mới
// const canGetRoleForUserResource = "GET::/api/v1/roles/users/:id";//Lấy thông tin role của user
// const canCreatePermissionBasicResource = "POST::/api/v1/roles/base-permissions"; //Thêm quyền cơ sở
// // const canGetListPermissionBasicResource = "GET::/api/v1/roles/base-permissions"; //Lấy danh sách quyền cơ sở
export const setUserPermissions = (user: IUser) => {
    const permResources = user.listPermission;

    // Quản lý nạp tiền
    user.canGetListDepositTransaction = permResources.includes(canGetListDepositTransactionResource);

    // Quản lý rút tiền
    user.canGetListWithdrawTransaction = permResources.includes(canGetListWithdrawTransactionResource);
    user.canUpdateStatusTransactionAdmin = permResources.includes(canUpdateStatusTransactionAdminResource);

    // Quản lý khách hàng
    user.canGetListCustomerAdmin = permResources.includes(canGetListCustomerAdminResource);
    user.canDepositAdmin = permResources.includes(canDepositAdminResource);
    user.canWithdrawAdmin = permResources.includes(canWithdrawAdminResource);
    user.canUpdateCustomerPassword = permResources.includes(canUpdateCustomerPasswordResource);
    user.canGetListBankCustomer = permResources.includes(canGetListBankCustomerResource);
    user.canUpdateBankCustomer = permResources.includes(canUpdateBankCustomerResource);
    user.canDeleteBankCustomer = permResources.includes(canDeleteBankCustomerResource);

    // Quản lý quản trị viên
    user.canQueryListUser = permResources.includes(canQueryListUserResource);
    user.canQueryListRole = permResources.includes(canQueryListRoleResource);
    user.canUpdateUserAdmin = permResources.includes(canUpdateUserAdminResource);
    user.canCreateUser = permResources.includes(canCreateUserResource);
    user.canQueryUserBasic = permResources.includes(canQueryUserBasicResource);
    user.canGetListPermissionBasic = permResources.includes(canGetListPermissionBasicResource);
    user.canGetRole = permResources.includes(canGetRoleResource);
    user.canUpdateRole = permResources.includes(canUpdateRoleResource);
    user.canCreateRole = permResources.includes(canCreateRoleResource);

    // Quản lý shark
    user.canGetListShark = permResources.includes(canGetListSharkResource);
    user.canCreateShark = permResources.includes(canCreateSharkResource);

    // Quản lý đơn hàng
    user.canGetListVote = permResources.includes(canGetListVoteResource);

//     //Nhóm quản trị
//     user.canUpdateStatusTransactionAdmin = permResources.includes(canUpdateStatusTransactionAdminResource);
//     user.canGetLisTransactionAdmin = permResources.includes(canGetLisTransactionAdminResource);
//     user.canGetListCustomerAdmin = permResources.includes(canGetListCustomerAdminResource);
//     user.canTopUpWithdrawAdmin = permResources.includes(canTopUpWithdrawAdminResource);
    // user.canAdminGroupManager = user.canUpdateStatusTransactionAdmin ||
//         user.canGetLisTransactionAdmin ||
//         user.canGetListCustomerAdmin ||
//         user.canAdminGroupManager

//     //Nhóm người dùng
//     user.canUpdateUserAdmin = permResources.includes(canUpdateUserAdminResource);
//     user.canUpdateUser = permResources.includes(canUpdateUserResource);
//     user.canQueryUserAdvance = permResources.includes(canQueryUserAdvanceResource);
//     user.canDeleteUser = permResources.includes(canDeleteUserResource);
//     user.canCreateUser = permResources.includes(canCreateUserResource);
//     user.canQueryUserBasic = permResources.includes(canQueryUserBasicResource);
//     user.canQueryListUser = permResources.includes(canQueryListUserResource);
//     user.canUserGroupManager = user.canUpdateUserAdmin ||
//         user.canUpdateUser ||
//         user.canQueryUserAdvance ||
//         user.canDeleteUser ||
//         user.canCreateUser ||
//         user.canQueryUserBasic ||
//         user.canQueryListUser ||
//         user.canQueryListUser


//     // Nhóm khách hàng
//     user.canWithdrawCustomer = permResources.includes(canWithdrawCustomerResource);
//     user.canGetBankCustomer = permResources.includes(canGetBankCustomerResource);
//     user.canGetListBankCustomer = permResources.includes(canGetListBankCustomerResource);
//     user.canCreateBankCustomer = permResources.includes(canCreateBankCustomerResource);
//     user.canGetCustomer = permResources.includes(canGetCustomerResource);
//     user.canGetListCustomer = permResources.includes(canGetListCustomerResource);
//     user.canCustomerManager = user.canWithdrawCustomer ||
//         user.canGetBankCustomer ||
//         user.canGetListBankCustomer ||
//         user.canCreateBankCustomer ||
//         user.canGetCustomer ||
//         user.canGetListCustomer


// // Nhóm phân quyền
//     user.canDeletePermissionBasic = permResources.includes(canDeletePermissionBasicResource);
//     user.canUpdatePermissionBasic = permResources.includes(canUpdatePermissionBasicResource);
//     user.canGetRole = permResources.includes(canGetRoleResource);
//     user.canDeleteRole = permResources.includes(canDeleteRoleResource);
//     user.canUpdateRole = permResources.includes(canUpdateRoleResource);
//     user.canListRole = permResources.includes(canListRoleResource);
//     user.canCreateRole = permResources.includes(canCreateRoleResource);
//     user.canGetRoleForUser = permResources.includes(canGetRoleForUserResource);
//     user.canCreatePermissionBasic = permResources.includes(canCreatePermissionBasicResource);
//     user.canGetListPermissionBasic = permResources.includes(canGetListPermissionBasicResource);
//     user.canRoleManager = user.canDeletePermissionBasic ||
//         user.canUpdatePermissionBasic ||
//         user.canGetRole ||
//         user.canDeleteRole ||
//         user.canUpdateRole ||
//         user.canListRole ||
//         user.canCreateRole ||
//         user.canGetRoleForUser ||
//         user.canCreatePermissionBasic ||
//         user.canGetListPermissionBasic


}

const hasAnyAuthority = (functions: string[], hasAnyFunctions: string[]) => {
    if (_.isEmpty(functions)) {
        return false;
    }
    if (hasAnyFunctions.length === 0) {
        return true;
    }
    return hasAnyFunctions.some(auth => functions.includes(auth));
};
const PermUtils = {
    canGetListDepositTransactionResource,
    // Quản lý rút tiền
    canGetListWithdrawTransactionResource,
    canUpdateStatusTransactionAdminResource,
    // Quản lý khách hàng
    canGetListCustomerAdminResource,
    canDepositAdminResource,
    canWithdrawAdminResource,
    canUpdateCustomerPasswordResource,
    canGetListBankCustomerResource,
    canUpdateBankCustomerResource,
    canDeleteBankCustomerResource,
    // Quản lý quản trị viên 
    canQueryListUserResource,
    canQueryListRoleResource,
    canUpdateUserAdminResource,
    canCreateUserResource,
    canQueryUserBasicResource,
    canGetListPermissionBasicResource,
    canGetRoleResource,
    canListRoleResource,
    canUpdateRoleResource,
    canCreateRoleResource,
    // Quản lý shark
    canGetListSharkResource,
    canCreateSharkResource,
    // Quản lý đơn hàng
    canGetListVoteResource,

    setUserPermissions,
    hasAnyAuthority
}

export default PermUtils
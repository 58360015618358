import { Breadcrumb } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import translate from "../../constants/translate";

const routes: Record<string, string> = {
  "/user": "User",
  "/customers": translate.customers,
  "/sharks": translate.shark,
  "/votes": translate.votes,
  "/banks": "Ngân hàng",
  // "/transactions": "Giao dịch",
  "/transactions/withdraw": "Giao dịch rút tiền",
  "/transactions/deposit": "Giao dịch nạp tiền",
  "/admin": translate.admin,
  "/admin/:id": 'Chỉnh sửa',
};

const BreadcrumbItem = () => {
  const location = useLocation();

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((str, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    let breadcrumbName = "";
    Object.keys(routes).forEach((pattern) => {
      const regex = new RegExp(pattern);

      if (regex.test(url)) {
        breadcrumbName = routes[pattern];
      }
    });

    return {
      key: url,
      title: breadcrumbName,
    };
  });

  return <Breadcrumb items={extraBreadcrumbItems} />;
};
export default BreadcrumbItem;

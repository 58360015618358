import { Button, Form, Input } from "antd"
import { LeftCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { apiGetBasePermissions, apiPostRole } from "../../api"
import { toastError, toastSuccess } from "../../utils/toast"
import ListGroupCheckbox, {ListCheckbox} from "./ListGroupCheckbox"
import ButtonActions from "./ButtonActions"
import md5 from 'blueimp-md5';

export default function RoleCreate() {
    const navigate = useNavigate()
    const [formCreate] = Form.useForm()
    const [groupPermissions, setGroupPermissions] = useState<any>({})
    const [valueChecked, setValueChecked] = useState<any>({
        admin: [] as any,
        user: [] as any,
        role: [] as any,
        customer: [] as any,
    })
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState<any>({});

    const fetchData = async () => {
        try {
            const resBasePermissions = await apiGetBasePermissions({})
            if (resBasePermissions.status === 200 && resBasePermissions.data) {
                const permissions = resBasePermissions.data
                let permissionsGroup = {} as any;
                if (Array.isArray(permissions)) {
                    // eslint-disable-next-line array-callback-return
                    permissions.map((item: any) => {
                        const key = md5(item.group);
                        const groupName = item.group;
                        if (key && groupName){
                            if (!permissionsGroup[key as keyof typeof permissionsGroup]) {
                                permissionsGroup[key as keyof typeof permissionsGroup] = {
                                    key,
                                    name: groupName,
                                    permissions: [] as any,
                                }
                            }
                            permissionsGroup[key as keyof typeof permissionsGroup]?.permissions.push({
                                label: item.description,
                                value: `${item.action}::${item.resource}`,
                            });
                        };
                    })
                }
                setGroupPermissions(permissionsGroup)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleBackPage = () => {
        navigate(-1)
    }

    const handleCreate = async ({ name }: { name: string }) => {
        const permissions = [] as any

        // Object.keys(valueChecked).forEach((key: any) => {
        //     valueChecked[key].map((item: any) => {
        //         const [action, resource] = item.split('::')
        //         permissions.push({ action, resource })
        //     })
        // })
        Object.keys(selectedPermissions).forEach((key: any) => {
            selectedPermissions[key]?.map((item: any) => {
                const [action, resource] = item?.value?.split('::')
                permissions.push({ action, resource })
            })
        })
        try {
            const res = await apiPostRole({ name, permissions })
            if (res.status === 200) {
                toastSuccess('Thêm phân quyền thành công!')
                handleBackPage()
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }

    return (
        <div>
            <Button
                onClick={handleBackPage}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white', marginBottom: 20 }}
            >
                Trở lại
            </Button>
            <Form form={formCreate} onFinish={handleCreate} layout="vertical">
                <Form.Item name='name' label='Tên phân quyền'
                    rules={[{ required: true, message: 'Vui lòng nhập tên phân quyền!' }]}>
                    <Input />
                </Form.Item>
                <ListCheckbox
                    groupPermissions={groupPermissions}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles} 
                    selectedPermissions={selectedPermissions}
                    setSelectedPermissions={setSelectedPermissions}
                />
                <ButtonActions />
            </Form>
        </div>
    )
}

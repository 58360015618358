import React from "react";
import {AUTH_LOGIN, PROFILE} from "../../../assets/constants/route_path";
import NAME_SPACES from "../../../i18n/helpers/nameSpaces";
import {useAppTranslation} from "../../../hook";
import {Button, Form, type FormProps, Input, Row} from 'antd';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getSession, login} from "../../../redux/slices/authSlice";
import {toastError} from "../../../utils/toast";
import md5 from "blueimp-md5";
import {SECRET_KEY} from "../../../assets/constants/constants";
import translate from "../../../constants/translate";
type FieldType = {
    username?: string;
    password?: string;
};

const LoginComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const {translate} = useAppTranslation([NAME_SPACES.AUTH]);
    const onFinish: FormProps<FieldType>["onFinish"] =async (values) => {
        try{
            const {username, password} =values
            if (username && password){
                let hashPassword = password
                if (username!="admin"){
                    hashPassword = md5( password, SECRET_KEY)
                }
                const resp = await dispatch(login({ username: username, password: hashPassword })).unwrap()
                // console.log("resp", resp)
                const sessionResp = await dispatch(getSession()).unwrap()
                console.log("sessionResp", sessionResp)
                navigate(PROFILE)
            }

         }
         catch (error: any) {
            const message = error && (error?.data?.message || error?.message || error)
            toastError(message)
            navigate(AUTH_LOGIN)
        }finally {
            console.log('Login Success:');
        }
    };
    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div
            style={{
                padding: 20,
                backgroundColor: "#FFF",
                borderRadius: 10,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                maxWidth: 400,
            }}>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"

            >
                <Form.Item<FieldType>
                    label={translate.username}
                    name="username"
                    rules={[{ required: true, message: translate.requireUsername }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label={translate.password}
                    name="password"
                    rules={[{ required: true, message: translate.requirePassword }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        {translate.login}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginComponent;

import { useCallback, useEffect, useState } from 'react'
import {
    apiGetRoles,
    apiGetBasePermissions,
    apiDeleteRole
} from '../../api'
import { Button, Modal, TableProps, Tag, Space } from 'antd';
import {
    EditOutlined, DeleteOutlined,
    RightOutlined, DownOutlined
} from "@ant-design/icons";
import CustomTable from '../../components/table';
import defaultParams from '../../assets/constants/default_params';
import { Link, useNavigate } from 'react-router-dom';
import { ROLES, ROLE_CREATE } from '../../assets/constants/route_path';
import { toastError, toastSuccess } from '../../utils/toast';

export default function Roles() {
    const [basePermissions, setBasePermissions] = useState<any>({})
    const [roles, setRoles] = useState<any>([])
    const [modalDelete, setModalDelete] = useState<any>({
        isOpen: false,
        user: {}
    })
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const navigate = useNavigate()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'Mã định danh',
            dataIndex: 'id',
        },
        {
            key: 'name',
            title: 'Tên Phân quyền',
            dataIndex: 'name',
            width: 200,
        },
        {
            key: 'permissions',
            title: 'Chức năng',
            dataIndex: 'permissions',
            render: (permissions: any) => {
                if (permissions != null && permissions.length > 0) {
                    const permissionsDisplay = permissions.map((item: any) => {
                        for (let index = 0; index < basePermissions.length; index++) {
                            if (basePermissions[index].action === item.action && basePermissions[index].resource === item.resource) {
                                if (basePermissions[index].description === "Lấy danh sách khách hàng") {
                                    console.log("ddddđ", basePermissions[index].description, item)
                                }
                                return <Tag key={`${index}`} color='purple'>{basePermissions[index].description}</Tag>
                            }
                        }
                    })
                    return permissionsDisplay
                }
                return <></>
            }
        },
        {
            title: 'Tác vụ',
            dataIndex: "action",
            key: "action",
            render: (_: any, data: any) =>{
                if (data.id==1) {
                    return <></>
                }
                return (
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Button
                            onClick={() => navigate(`${ROLES}/${data.id}`)}
                            style={{ background: '#0c7adf', color: 'white' }}
                            icon={<EditOutlined />}
                            disabled={data.id==1}
                        >
                            Sửa
                        </Button>
                        <Button
                            onClick={() => setModalDelete({
                                isOpen: true,
                                role: data
                            })}
                            style={{ background: '#c93c3c', color: 'white' }}
                            icon={<DeleteOutlined />}
                            disabled={data.id==1}
                        >
                            Xoá
                        </Button>
                    </div>
                )

            } ,
        },
    ]

    const fetchData = useCallback(async () => {
        try {
            const resBasePermission = await apiGetBasePermissions({
                page: currentPage,
                limit: pageSize,
            })

            if (resBasePermission.status === 200 && resBasePermission.data) {
                setBasePermissions(resBasePermission.data)
            }
            const resRoles = await apiGetRoles({})
            if (resRoles.status === 200 && resRoles.data) {
                setRoles(resRoles)
            }
        } catch (error) {
            console.log(error)
        }
    }, [currentPage, pageSize])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleDelete = async () => {
        try {
            const res = await apiDeleteRole(modalDelete.role.id)
            console.log('Response-----------', res);
            if (res.status === 200) {
                fetchData()
                setModalDelete({
                    isOpen: false,
                    user: {}
                })
                toastSuccess('Xoá phân quyền thành công!')
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }


    const permissionGroupRender = (name: any, permissions: any) => {
        if (Array.isArray(permissions) && permissions.length === 0) {
            return <></>
        }
        return <>
            <strong>{name}</strong>
            <ul>
                {Array.isArray(permissions) && permissions.map(item => <li>{item.description}</li>)}
            </ul>
        </>
    }

    return (
        <div>
            <div style={{ textAlign: 'end', marginBottom: 10 }}>
                <Space>
                    <Link to={ROLE_CREATE}>
                        <Button type="primary">Thêm vai trò</Button>
                    </Link>
                </Space>
            </div>
            <CustomTable
                namePage='Phân quyền'
                expandable={{
                    expandedRowRender: (record: any) => {
                        console.log("expandedRowRender", record)
                        const permissions = record.permissions
                        let permissionsGroup = {
                            customer: {
                                key: "customer",
                                name: "Khách hàng",
                                permissions: [] as any,
                            },
                            admin: {
                                key: "admin",
                                name: "Quản trị",
                                permissions: [] as any,
                            },
                            role: {
                                key: "role",
                                name: "Role",
                                permissions: [] as any,
                            },
                            user: {
                                key: "user",
                                name: "Người dùng",
                                permissions: [] as any,
                            }
                        }
                        if (Array.isArray(permissions)) {
                            permissions.map((item: any) => {
                                for (let index = 0; index < basePermissions.length; index++) {
                                    if (basePermissions[index].action === item.action && basePermissions[index].resource === item.resource) {
                                        for (const [key, value] of Object.entries(permissionsGroup)) {
                                            if (value.name === basePermissions[index].group) {
                                                permissionsGroup[key as keyof typeof permissionsGroup].permissions.push(basePermissions[index])
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        return (
                            <div>
                                <hr />
                                <div style={{ margin: '10px 0' }}>
                                    <b style={{ display: 'block', paddingBottom: 10, borderBottom: '1px dashed gray' }}>
                                        Tên vai trò: <Tag color='green'>  {record.name}</Tag>
                                    </b>
                                </div>
                                <hr />
                                <div style={{ margin: '10px 0' }}>
                                    <b style={{ display: 'block', paddingBottom: 10, borderBottom: '1px dashed gray' }}>
                                        Phân quyền chi tiết:
                                    </b>
                                    {permissionGroupRender('Nhóm quản trị', permissionsGroup['admin'].permissions)}
                                    {permissionGroupRender('Nhóm người dùng', permissionsGroup['user'].permissions)}
                                    {permissionGroupRender('Nhóm khách hàng', permissionsGroup['customer'].permissions)}
                                    {permissionGroupRender('Nhóm phân quyền', permissionsGroup['role'].permissions)}

                                </div>
                            </div>
                        )
                    },
                    expandIcon: ({ expanded, onExpand, record }: any) =>
                        expanded
                            ? <DownOutlined onClick={e => onExpand(record, e)} />
                            : <RightOutlined onClick={e => onExpand(record, e)} />
                }}
                columns={columns}
                dataSource={roles.data}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={roles.Size}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Xoá phân quyền' open={modalDelete.isOpen}
                onCancel={() => setModalDelete({
                    isOpen: false,
                    role: {}
                })}
                footer={[
                    <Button onClick={handleDelete} style={{ background: '#c93c3c', color: 'white' }}>
                        Xoá
                    </Button>,
                ]}>
                {modalDelete.role && (
                    <div>Bạn có chắc muốn xoá <b>{modalDelete.role.name}</b>?</div>
                )}
            </Modal>
        </div>
    )
}

import APIUtils from './base';
import { ILoginParams } from '../models/login.model';
import { overrideAxiosDefaultConfig } from './config';
import { setupAxiosInterceptors } from './interceptor';
export const apiLogin = (params: ILoginParams) => APIUtils.post('/api/v1/auth/token', params);
// User APIs
export const apiGetUserProfile = (params: { uid: string }) => APIUtils.get(`/api/v1/users/${params.uid}`);
export const apiGetUsers = (params: any) => APIUtils.get(`/api/v1/users`, params);
export const apiPostUser = (data: any) => APIUtils.post(`/api/v1/users/new-user`, data);
export const apiDeleteUser = (uid: string) => APIUtils.delete(`/api/v1/users/account/${uid}`);
export const apiUpdateUser = (uid: string, data: any) => APIUtils.patch(`/api/v1/users/${uid}`, data);
export const apiUpdateUserProfile = (uid: string, data: any) => APIUtils.put(`/api/v1/users/${uid}/profile-by-admin`, data);
export const apiChangePassword = (uid: string, data: any) => APIUtils.patch(`/api/v1/users/${uid}/password`, data);
// Role APIs
export const apiGetRoles = (params: any) => APIUtils.get(`/api/v1/roles`, params);
export const apiPostRole = (data: any) => APIUtils.post(`/api/v1/roles`, data);
export const apiGetBasePermissions = (params: any) => APIUtils.get(`/api/v1/roles/base-permissions`, params);
export const apiGetRoleByUser = (userId: any) => APIUtils.get(`/api/v1/roles/users/${userId}`);
export const apiGetRoleById = (id: any) => APIUtils.get(`/api/v1/roles/${id}`);

export const apiDeleteRole = (uid: string) => APIUtils.delete(`/api/v1/roles/${uid}`);

export const apiGetRole = (params: { uid: string }) => APIUtils.get(`/api/v1/roles/${params.uid}`);
export const apiUpdateRole = (params: { uid: string }, data: any) => APIUtils.put(`/api/v1/roles/${params.uid}`, data);

export const settingAPI = ({ onUnauthenticatedCallback }: { onUnauthenticatedCallback: Function }) => {
    overrideAxiosDefaultConfig();
    setupAxiosInterceptors(onUnauthenticatedCallback);
};
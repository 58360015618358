import { useCallback, useEffect, useState } from 'react'
import { apiDeleteUser, apiGetRoles, apiGetUsers, apiPostUser, apiUpdateUser } from '../../api'
import { Button, Input, Modal, TableProps, Form, Tag, Select, Space } from 'antd';
import {
    EditOutlined, DeleteOutlined,
    CheckOutlined, LockOutlined,
    RightOutlined, DownOutlined
} from "@ant-design/icons";
import translate from '../../constants/translate';
import CustomTable from '../../components/table';
import FormSearch from '../../components/form';
import defaultParams from '../../assets/constants/default_params';
import { useNavigate } from 'react-router-dom';
import { ADMIN } from '../../assets/constants/route_path';
import { toastError, toastSuccess } from '../../utils/toast';
import { SECRET_KEY } from '../../assets/constants/constants';
import md5 from 'blueimp-md5';
import Permission from './Permission';

const DEFAULT = {
    roles: '-3,-1,-2',
    sort: '-desc',
}

export default function Admin() {
    const [users, setUsers] = useState<any>([])
    const [roles, setRoles] = useState<any>([])
    const [rolesSearch, setRolesSearch] = useState<any>([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [modalDelete, setModalDelete] = useState<any>({
        isOpen: false,
        user: {}
    })
    const [modalActive, setModalActive] = useState<any>({
        isOpen: false,
        user: {}
    })
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState<object>({
        username: '',
        name: '',
        roles: DEFAULT.roles,
        sort: DEFAULT.sort,
    })
    const [formAdd] = Form.useForm()
    const [formSearch] = Form.useForm()
    const navigate = useNavigate()

    const columns: TableProps<any>['columns'] = [
        {
            key: 'id',
            title: 'Mã định danh',
            dataIndex: 'id',
        },
        {
            key: 'username',
            title: 'Tên đăng nhập',
            dataIndex: 'username',
        },
        {
            key: 'first_name',
            title: 'Tên người dùng',
            dataIndex: 'first_name',
        },
        {
            key: 'group',
            title: 'Nhóm quản trị',
            dataIndex: 'role',
            render: (role: any) => <Tag color='purple'>{role.name}</Tag>
        },
        {
            key: 'active',
            title: 'Trạng thái',
            render: (data: any) => (
                <Button type='text' onClick={() => setModalActive({
                    isOpen: true,
                    user: data
                })}>
                    <Tag
                        icon={data.active ? <CheckOutlined /> : <LockOutlined />}
                        color={data.active ? 'green' : 'red'}
                    >
                        {data.active ? 'Hoạt động' : 'Đang khoá'}
                    </Tag>
                </Button>
            )
        },
        {
            title: 'Tác vụ',
            dataIndex: "action",
            key: "action",
            render: (_: any, data: any) => (
                <div style={{ display: 'flex', gap: 10 }}>
                    <Button
                        onClick={() => navigate(`${ADMIN}/${data.id}`)}
                        style={{ background: '#0c7adf', color: 'white' }}
                        icon={<EditOutlined />}
                    >
                        Sửa
                    </Button>
                    <Button
                        onClick={() => setModalDelete({
                            isOpen: true,
                            user: data
                        })}
                        style={{ background: '#c93c3c', color: 'white' }}
                        icon={<DeleteOutlined />}
                    >
                        Xoá
                    </Button>
                </div>
            ),
        },
    ]

    const fetchUser = useCallback(async () => {
        try {
            const resUsers = await apiGetUsers({
                page: currentPage,
                limit: pageSize,
                ...search
            })
            if (resUsers.status === 200 && resUsers.data) {
                setUsers(resUsers.data)
            }
        } catch (error) {
            console.log(error)
        }
    }, [currentPage, pageSize, search])

    const fetchRoles = useCallback(async () => {
        try {
            const resRoles = await apiGetRoles({})
            if (resRoles.status === 200 && resRoles.data) {
                const rolesValue = resRoles.data
                    .filter((item: any) => item.id !== 1 && item.id !== 3)
                    .map((item: any) => ({ ...item, value: item.id, label: item.name }))
                setRoles(rolesValue)

                const dataRolesSearch = resRoles.data
                    .filter((item: any) => item.id !== 1 && item.id !== 3)
                    .map((item: any) => ({ value: `${item.id}`, label: item.name }))
                setRolesSearch(dataRolesSearch)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        fetchUser()
        fetchRoles()
    }, [fetchUser, fetchRoles])

    const handleAdd = async (data: any) => {
        const { username, password, rePassword, role } = data
        if (password !== rePassword) {
            toastError('Nhắc lại mật khẩu không chính xác!')
            return
        }
        try {
            const hashPassword = md5(password, SECRET_KEY)
            const roleId = role ? parseInt(role) : 0;
            const res = await apiPostUser({ username, password: hashPassword, role: { id: roleId } })
            if (res.status === 200) {
                formAdd.resetFields()
                fetchUser()
                setIsOpenModal(false)
                toastSuccess('Thêm tài khoản thành công!')
            }
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
        }
    }

    const handleUpdateActive = async () => {
        try {
            if (modalActive.user) {
                const res = await apiUpdateUser(modalActive.user.id, { ...modalActive.user, active: !modalActive.user.active })
                if (res.status === 200) {
                    fetchUser()
                    setModalActive({
                        isOpen: false,
                        user: {}
                    })
                    toastSuccess('Cập nhật trạng thái thành công!')
                }
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }

    const handleDelete = async () => {
        try {
            const res = await apiDeleteUser(modalDelete.user.id)
            if (res.status === 200) {
                fetchUser()
                setModalDelete({
                    isOpen: false,
                    user: {}
                })
                toastSuccess('Xoá tài khoản thành công!')
            }
        } catch (error) {
            toastError('Có lỗi xảy ra!')
        }
    }

    const handleSearch = async ({ username, name, roles, sort }: any) => {
        if (roles === undefined) {
            roles = DEFAULT.roles
        }
        if (sort === undefined) {
            sort = DEFAULT.sort
        }
        setSearch({ username, name, roles, sort })
        setCurrentPage(defaultParams.PAGE)
    }

    const handleClearSearch = async () => {
        setSearch({
            username: '',
            name: '',
            roles: DEFAULT.roles,
            sort: DEFAULT.sort,
        })
        setCurrentPage(defaultParams.PAGE)
        formSearch.resetFields()
    }

    return (
        <div>
            <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
                <Form.Item name='username' label='Tên đăng nhập'>
                    <Input />
                </Form.Item>
                <Form.Item name='name' label='Tên người dùng'>
                    <Input />
                </Form.Item>
                <Form.Item name='roles' label='Nhóm quản trị'>
                    {rolesSearch && (
                        <Select
                            defaultValue={DEFAULT.roles}
                            style={{ width: 180 }}
                            options={[{ value: DEFAULT.roles, label: 'Tất cả' }, ...rolesSearch]}
                        />
                    )}
                </Form.Item>
                <Form.Item name='sort' label='Sắp xếp'>
                    <Select
                        defaultValue={DEFAULT.sort}
                        style={{ width: 180 }}
                        options={[
                            { value: '-desc', label: 'Giảm dần thời gian tạo' },
                            { value: '', label: 'Tăng dần thời gian tạo' },
                        ]}
                    />
                </Form.Item>
            </FormSearch>
            <div style={{ textAlign: 'end', marginBottom: 10 }}>
                <Space>
                    <Button onClick={() => setIsOpenModal(true)} type="primary">Thêm tài khoản</Button>
                    <Modal title='Thêm tài khoản' open={isOpenModal}
                        onCancel={() => setIsOpenModal(false)}
                        footer={[
                            <Button key="submit" type="primary" htmlType="submit" form="form-add">
                                {translate.add}
                            </Button>,
                        ]}>
                        <Form
                            name='form-add'
                            form={formAdd}
                            style={{ marginTop: 20 }}
                            onFinish={handleAdd}
                            labelAlign='left'
                            labelCol={{ span: 7 }}>
                            <Form.Item label='Tên đăng nhập' name="username"
                                rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Mật khẩu' name="password"
                                rules={[{ required: true, min: 6, message: 'Vui lòng nhập mật khẩu và ít nhất 6 ký tự!' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label='Nhắc lại mật khẩu' name="rePassword"
                                rules={[{ required: true, min: 6, message: 'Vui lòng nhập nhắc lại mật khẩu và ít nhất 6 ký tự!' }]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item name='role' label='Chọn vai trò'>
                                <Select
                                    // defaultValue={DEFAULT.roleId}
                                    style={{ width: '100%' }}
                                    options={roles}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Space>
            </div>
            <CustomTable
                namePage='quản trị viên'
                expandable={{
                    expandedRowRender: (record: any) => (
                        <div>
                            <div>Họ tên: {record.last_name + ' ' + record.first_name}</div>
                            <hr />
                            <div style={{ margin: '10px 0' }}>
                                <b style={{ display: 'block', paddingBottom: 10, borderBottom: '1px dashed gray' }}>
                                    Nhóm quản trị:
                                </b>
                                <div style={{ paddingTop: 10 }}>
                                    <Tag color='purple'>{record.role.name}</Tag>
                                </div>
                            </div>
                            <hr />
                            <div style={{ margin: '10px 0' }}>
                                <b style={{ display: 'block', paddingBottom: 10, borderBottom: '1px dashed gray' }}>
                                    Phân quyền chi tiết:
                                </b>
                                <div style={{ paddingTop: 10 }}>
                                    <Permission roleId={record.role.id} />
                                </div>
                            </div>
                            <hr />
                            <div style={{ margin: '10px 0' }}>
                                <b style={{ display: 'block', paddingBottom: 10, borderBottom: '1px dashed gray' }}>
                                    Phiên đăng nhập mới nhất:
                                </b>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10, paddingTop: 10 }}>
                                    <span>{record.last_login_at != undefined ? record.last_login_at : "N/A"}</span>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        IP: <b style={{ color: 'red' }}>{record.last_login_ip != undefined ? record.last_login_ip : "N/A"}</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }: any) =>
                        expanded
                            ? <DownOutlined onClick={e => onExpand(record, e)} />
                            : <RightOutlined onClick={e => onExpand(record, e)} />
                }}
                columns={columns}
                dataSource={users.Models}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={users.Size}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            {modalDelete.user && (
                <Modal title='Xoá tài khoản' open={modalDelete.isOpen}
                    onCancel={() => setModalDelete({
                        isOpen: false,
                        user: {}
                    })}
                    footer={[
                        <Button onClick={handleDelete} style={{ background: '#c93c3c', color: 'white' }}>
                            Xoá
                        </Button>,
                    ]}>
                    <div>Bạn có chắc muốn xoá người dùng <b>{modalDelete.user.username}</b>?</div>
                </Modal>
            )}
            {modalActive.user && (
                <Modal title='Cập nhật trạng thái' open={modalActive.isOpen}
                    onCancel={() => setModalActive({
                        isOpen: false,
                        user: {}
                    })}
                    footer={[
                        <Button onClick={handleUpdateActive} style={{ background: modalActive.user.active ? '#c93c3c' : '#008965', color: 'white' }}>
                            {modalActive.user.active ? 'Khoá lại' : 'Mở khoá'}
                        </Button>,
                    ]}>
                    <div>Bạn có chắc muốn cập nhật người dùng <b>{modalActive.user.username}</b>?</div>
                </Modal>
            )}
        </div>
    )
}

import { Button, Modal } from 'antd'
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { formatCurrency } from '../../utils/string-utils'

export default function ModalHandle({ data, setData, handle, renderTxType }: any) {
    const styleRow: object = {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 20,
        padding: '4px 0'
    }

    return (
        <Modal
            title={<div style={{ fontSize: 20, textAlign: 'center' }}>Xử lý giao dịch</div>}
            open={data.isOpen}
            footer={[
                <Button
                    disabled={data.data?.customer?.in_balance < data.data?.amount}
                    onClick={() => handle('completed')}
                    icon={<CheckOutlined />}
                    style={{ background: (data.data?.customer?.in_balance >= data.data?.amount) ? '#008965' : '#85A19A', color: 'white' }}>
                    Duyệt
                </Button>,
                <Button
                    onClick={() => handle('cancelled')}
                    icon={<StopOutlined />}
                    style={{ background: '#c93c3c', color: 'white' }}>
                    Không duyệt
                </Button>,
            ]}
            onCancel={setData}>
            {data.data && (
                <div>
                    <div style={styleRow}>
                        <b>Khách hàng:</b>
                        <span>{data.data.customer?.user.username}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Số dư hiện tại:</b>
                        <span>{formatCurrency(data.data.customer?.in_balance)}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Tên ngân hàng:</b>
                        <span>{data.data.bank?.bank_name}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Số tài khoản:</b>
                        <span>{data.data.bank?.card_number}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Chủ tài khoản:</b>
                        <span>{data.data.bank?.account_name}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Số tiền giao dịch:</b>
                        {data?.data?.customer?.in_balance < data.data.amount 
                          ?<span style={{color:"red"}}>{formatCurrency(data.data.amount)}</span>
                          :<span>{formatCurrency(data.data.amount)}</span>
                        }
                    </div>
                    {data?.data?.customer?.in_balance < data.data.amount &&
                        <span style={{color:"red"}}>{`* Số tiền muốn rút lớn hơn số dư hiện tại`} </span>
                    }
                    <hr />
                    <div style={styleRow}>
                        <b>Loại giao dịch:</b>
                        <span>{renderTxType(data.data.state)}</span>
                    </div>
                    <hr />
                    <div style={styleRow}>
                        <b>Thời gian tạo đơn:</b>
                        <span>{data.data.created_at}</span>
                    </div>
                    <hr />
                    {data?.data?.operator && (
                        <>
                            <div style={styleRow}>
                                <b>Xử lý bởi:</b>
                                <span>{data.data.operator}</span>
                            </div>
                            <hr />
                        </>
                    )}
                    <div style={styleRow}>
                        <b>Mô tả:</b>
                        <span>{data.data.desc}</span>
                    </div>
                </div>
            )}
        </Modal>
    )
}

import { useEffect, useState } from 'react'
import { apiGetVotes } from '../../api/axios'
import { DatePicker, Form, Input, TableProps } from 'antd';
import translate from '../../constants/translate';
import CustomTable from '../../components/table';
import FormSearch from '../../components/form';
import dayjs from 'dayjs'
import defaultParams from '../../assets/constants/default_params';

const columns: TableProps<any>['columns'] = [
  {
    key: 'userId',
    title: 'Mã người dùng',
    dataIndex: 'user',
    render: (text) => text.user_id
  },
  {
    key: 'username',
    title: translate.username,
    dataIndex: 'user',
    render: (text) => text.username
  },
  {
    key: 'first_name',
    title: "Tên người dùng",
    dataIndex: 'user',
    render: (text) => text.first_name
  },
  {
    key: 'sharkId',
    title: 'Mã Shark',
    dataIndex: 'shark',
    render: (text) => text.id
  },
  {
    key: 'sharkName',
    title: translate.sharkName,
    dataIndex: 'shark',
    render: (text) => text.name
  },
  {
    key: 'point',
    title: "Điểm bình chọn",
    dataIndex: 'point',
  },
  {
    key: 'voted_at',
    title: translate.votedAt,
    dataIndex: 'voted_at',
  }
]

export default function Votes() {
  const [votes, setVotes] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [startDate, setStartDate] = useState<string>(defaultParams.START_DATE)
  const [endDate, setEndDate] = useState<string>(defaultParams.END_DATE)
  const [sharkIdKey, setSharkIdKey] = useState<string>('')
  const [userIdKey, setUserIdKey] = useState<string>('')
  const [usernameKey, setUsernameKey] = useState<string>('')
  const [custNameKey, setCustNameKey] = useState<string>('')
  const [sharkNameKey, setSharkNameKey] = useState<string>('')
  const [formSearch] = Form.useForm()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resVotes = await apiGetVotes({
          page: currentPage,
          size: pageSize,
          sharkId: sharkIdKey,
          userId: userIdKey,
          username: usernameKey,
          custName: custNameKey,
          sharkName: sharkNameKey,
          fromAt: startDate,
          toAt: endDate,
        })
        if (resVotes.status === 200) {
          setVotes(resVotes.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [currentPage, pageSize, sharkIdKey, userIdKey, usernameKey, custNameKey, sharkNameKey, startDate, endDate])

  const handleSearch = async ({ sharkId, userId, username, custName, sharkName, date }: any) => {
    if (date) {
      setStartDate(date[0].format(defaultParams.FORMAT_TIME))
      setEndDate(date[1].format(defaultParams.FORMAT_TIME))
    }
    setCurrentPage(1)
    setSharkIdKey(sharkId)
    setUserIdKey(userId)
    setUsernameKey(username)
    setCustNameKey(custName)
    setSharkNameKey(sharkName)
  }

  const handleClearSearch = async () => {
    setStartDate(defaultParams.START_DATE)
    setEndDate(defaultParams.END_DATE)
    setCurrentPage(defaultParams.PAGE)
    setSharkIdKey('')
    setUserIdKey('')
    setUsernameKey('')
    setCustNameKey('')
    setSharkNameKey('')
    formSearch.resetFields()
  }

  return (
    <div>
      <FormSearch form={formSearch} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
        <Form.Item name='sharkId' label='Mã Shark'>
          <Input />
        </Form.Item>
        <Form.Item name='sharkName' label='Tên Shark'>
          <Input />
        </Form.Item>
        <Form.Item name='userId' label='Mã người dùng'>
          <Input />
        </Form.Item>
        <Form.Item name='username' label='Tên tài khoản'>
          <Input />
        </Form.Item>
        <Form.Item name='custName' label='Tên người dùng'>
          <Input />
        </Form.Item>
        <Form.Item name='date' label='Thời gian bình chọn'>
          <DatePicker.RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(defaultParams.START_DATE, defaultParams.FORMAT_TIME),
              dayjs(defaultParams.END_DATE, defaultParams.FORMAT_TIME)
            ]}
            format={defaultParams.FORMAT_TIME}
          />
        </Form.Item>
      </FormSearch>
      <CustomTable
        namePage='bình chọn'
        columns={columns}
        dataSource={votes.items}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={votes.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

import { Checkbox, Form } from 'antd'

export default function GroupCheckbox({ name, data, value, setValue }: any) {
    return (
        <Form.Item label={name}>
            <div className="custom-checkbox-container">
                <Checkbox.Group
                    options={data}
                    defaultValue={value}
                    onChange={setValue} />
            </div>
        </Form.Item>
    )
}

export function CheckboxEx({ name, role, selectedRoles, setValue}: any) {
    return (
        <Checkbox
            checked={selectedRoles.includes(role)}
            onChange={() => setValue(role)} 
        >
            {name}
        </Checkbox>
)
}

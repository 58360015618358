import { useEffect, useState } from 'react'
import { apiDeleteBank, apiGetBanks, apiUpdateBank, getListBankPublic } from '../../api/axios'
import { Button, Form, Input, Modal, Select, TableProps } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CustomTable from '../../components/table';
import FormSearch from '../../components/form';
import defaultParams from '../../assets/constants/default_params';
import { toastError, toastSuccess } from '../../utils/toast';

export default function Banks() {
  const [banks, setBanks] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
  const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
  const [userIdKey, setUserIdKey] = useState<string>('')
  const [usernameKey, setUsernameKey] = useState<string>('')
  const [form] = Form.useForm()
  const [formUpdate] = Form.useForm()
  const [bankUpdate, setBankUpdate] = useState<any>({ isOpen: false, data: null })
  const [bankDelete, setBankDelete] = useState<any>({ isOpen: false, data: null })
  const [listBank, setListBank] = useState<any>([])

  const columns: TableProps<any>['columns'] = [
    {
      key: 'bank_id',
      title: "Mã ngân hàng",
      dataIndex: 'bank_id',
      render: (bank_id) => bank_id
    },
    {
      key: 'userId',
      title: "Mã định danh Khách hàng",
      dataIndex: 'user',
      render: (user) => user.user_id
    },
    {
      key: 'username',
      title: "Tên Khách hàng",
      dataIndex: 'user',
      render: (user) => user.username
    },
    {
      key: 'bank_name',
      title: "Ngân hàng",
      dataIndex: 'bank_name',
    },
    {
      key: 'account_name',
      title: "Họ và tên tài khoản",
      dataIndex: 'account_name',
    },
    {
      key: 'card_number',
      title: "Số tài khoản",
      dataIndex: 'card_number',
    },
    {
      title: 'Tác vụ',
      dataIndex: "action",
      key: "action",
      render: (_: any, data: any) => (
        <div style={{ display: 'flex', gap: 10 }}>
          <Button
            onClick={() => setBankUpdate({ data, isOpen: true })}
            style={{ background: '#0c7adf', color: 'white' }}
            icon={<EditOutlined />}
          >
            Sửa
          </Button>
          <Button
            onClick={() => setBankDelete({ data, isOpen: true })}
            style={{ background: '#c93c3c', color: 'white' }}
            icon={<DeleteOutlined />}
          >
            Xoá
          </Button>
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const resBanks = await apiGetBanks({
        page: currentPage,
        size: pageSize,
        userId: userIdKey,
        username: usernameKey,
      })
      if (resBanks.status === 200) {
        setBanks(resBanks.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, userIdKey, usernameKey])

  const handleSearch = async ({ userId, username}: any) => {
    setCurrentPage(defaultParams.PAGE)
    setUserIdKey(userId)
    setUsernameKey(username)
  }

  const handleClearSearch = async () => {
    setCurrentPage(defaultParams.PAGE)
    setUserIdKey('')
    setUsernameKey('')
    form.resetFields()
  }

  const handleUpdateBank = async ({ bank_name }: any) => {
    if (bank_name) {
      bankUpdate.data.bank_name = bank_name
    }

    try {
      const newData = bankUpdate.data
      const res = await apiUpdateBank(
        newData.bank_id,
        {
          bank_id: newData.bank_id,
          account_name: newData.account_name,
          bank_name: newData.bank_name,
          card_number: newData.card_number,
          user: {
            user_id: newData.user.user_id,
          }
        }
      )

      if (res.status === 200) {
        setBankUpdate({ isOpen: false, data: null })
        toastSuccess('Cập nhật ngân hàng thành công!')
        fetchData()
      }
    } catch (error) {
      console.log(error, '------');
      toastError('Có lỗi xảy ra!')
    }
  }

  const handleDeleteBank = async () => {
    try {
      const res = await apiDeleteBank(bankDelete.data.bank_id)
      if (res.status === 200) {
        setBankDelete({ isOpen: false, data: null })
        toastSuccess('Xoá ngân hàng thành công!')
        fetchData()
      }
    } catch (error) {
      console.log(error, '------');
      toastError('Có lỗi xảy ra!')
    }
  }

  const getListBanks = async () => {
    const resp = await getListBankPublic()
    if (resp.status == 200) {
      const banks = resp.data.data
      const data = banks.map((item: any) => {
        return { value: `${item.short_name} - ${item.name}`, label: `${item.short_name} - ${item.name}` }
      })
      setListBank(data)
    }
  }
  useEffect(() => {
    getListBanks()
  }, [])

  return (
    <div>
      <FormSearch form={form} handleSearch={handleSearch} handleClearSearch={handleClearSearch}>
        <Form.Item name='userId' label='Mã định danh khách hàng'>
          <Input />
        </Form.Item>
        <Form.Item name='username' label='Tài khoản khách hàng'>
          <Input />
        </Form.Item>
      </FormSearch>
      <CustomTable
        namePage='tài khoản ngân hàng'
        columns={columns}
        dataSource={banks.items}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={banks.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {bankUpdate.data && (
        <Modal title={`Cập nhật ngân hàng (${bankUpdate.data?.bank_id})`} open={bankUpdate.isOpen}
          onCancel={() => setBankUpdate({ isOpen: false, data: null })}
          footer={[
            <Button key="submit" type="primary" htmlType="submit" form='form-update-bank'>
              Cập nhật ngân hàng
            </Button>,
          ]}>
          <Form
            name='form-update-bank'
            form={formUpdate}
            style={{ marginTop: 20 }}
            onFinish={handleUpdateBank}
            labelAlign='left'
            labelCol={{ span: 8 }}>
            <Form.Item label='Tên ngân hàng'>
              <Select
                options={listBank}
                mode='tags'
                value={bankUpdate.data.bank_name}
                onChange={(value: any) => setBankUpdate({ ...bankUpdate, data: { ...bankUpdate.data, bank_name: value[value.length - 1] } })}
              />
            </Form.Item>
            <Form.Item label='Họ và tên tài khoản'>
              <Input required value={bankUpdate.data.account_name}
                onChange={(e: any) => setBankUpdate({ ...bankUpdate, data: { ...bankUpdate.data, account_name: e.target.value } })}
              />
            </Form.Item>
            <Form.Item label='Số tài khoản'>
              <Input required value={bankUpdate.data.card_number}
                onChange={(e: any) => setBankUpdate({ ...bankUpdate, data: { ...bankUpdate.data, card_number: e.target.value } })}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {bankDelete.data && (
        <Modal title={`Xoá ngân hàng (${bankDelete.data.bank_id})`} open={bankDelete.isOpen}
          onCancel={() => setBankDelete({ isOpen: false, data: null })}
          footer={[
            <Button onClick={handleDeleteBank} style={{ background: '#c93c3c', color: 'white' }}>
              Xoá
            </Button>,
          ]}>
          <div>Bạn có chắc muốn xoá ngân hàng <b>{bankDelete.data.bank_name} ({bankDelete.data.card_number})</b>?</div>
        </Modal>
      )}
    </div>
  )
}

import { Navigate } from "react-router-dom";
import { isEmpty } from "../../utils/string-utils";
import { AUTH_LOGIN, PROFILE } from "../../assets/constants/route_path";
import { useAppSelector } from "../../redux/store";
import { authSelector } from "../../redux/slices/authSlice";
import PermUtils from "../../utils/perm-utils";

const PrivateRoute = ({ hasAnyFunctions = [], children }: any) => {
  const auth = useAppSelector(authSelector);
  const { account } = auth;
  const isAuthorized = isEmpty(hasAnyFunctions) ||
  PermUtils.hasAnyAuthority(account.listPermission, hasAnyFunctions);
  return isAuthorized ? <>{children}</> : <Navigate to={AUTH_LOGIN} />;
};

export default PrivateRoute;

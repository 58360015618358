import { Button, Form, Input, Select, Space } from "antd"
import {
    LeftCircleOutlined,
    CheckOutlined, CloseOutlined
} from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { apiGetRoles, apiGetUserProfile, apiUpdateUserProfile } from "../../api"
import { ADMIN } from "../../assets/constants/route_path"
import { toastError, toastSuccess } from "../../utils/toast"

export default function AdminEdit() {
    const [user, setUser] = useState<any>({})
    const [roles, setRoles] = useState<any>([])
    const { id } = useParams()
    const navigate = useNavigate()
    const [formUpdate] = Form.useForm()

    useEffect(() => {
        const fetchUser = async () => {
            if (id) {
                try {
                    const { data } = await apiGetUserProfile({ uid: id })
                    setUser(data)
                    const resRoles = await apiGetRoles({})
                    if (resRoles.status === 200 && resRoles.data) {
                        const rolesValue = resRoles.data
                            .filter((item: any) => item.id !== 1 && item.id !== 3)
                            .map((item: any) => ({ value: item.id, label: item.name }))
                        setRoles(rolesValue)
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                navigate(ADMIN)
            }
        }
        fetchUser()
    }, [id, navigate])

    const handleBackPage = () => {
        navigate(-1)
    }

    const handleUpdate = async (data: any) => {
        const newData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== undefined)
        )
        if (Object.keys(newData).length === 0) {
            toastError('Chưa có thông  tin nào thay đổi!')
            return
        }

        try {
            const res = await apiUpdateUserProfile(user.id, data)
            if (res.status === 200) {
                toastSuccess('Cập nhật tài khoản thành công!')
            }
        } catch (error: any) {
            const err = error?._data?.message || 'Có lỗi xảy ra!'
            toastError(err)
        }
    }

    return (
        <div>
            <Button
                onClick={handleBackPage}
                icon={<LeftCircleOutlined />}
                style={{ background: 'gray', color: 'white', marginBottom: 20 }}
            >
                Trở lại
            </Button>
            <Form form={formUpdate} onFinish={handleUpdate} layout="vertical">
                <Form.Item name='last_name' label='Họ'>
                    <Input placeholder={user.last_name} />
                </Form.Item>
                <Form.Item name='first_name' label='Tên'>
                    <Input placeholder={user.first_name} />
                </Form.Item>
                <Form.Item label='Tên đăng nhập'>
                    <Input placeholder={user.username} disabled />
                </Form.Item>
                <Form.Item name='role_id' label='Vai trò'>
                    {user.role && (
                        <Select
                            defaultValue={user.role.id}
                            style={{ width: '100%' }}
                            options={roles}
                        />
                    )}
                </Form.Item>
                <div style={{ marginBottom: 20 }}>
                    <Form.Item name='active' label='Trạng thái tài khoản'>
                        {user.active !== undefined && (
                            <Select
                                defaultValue={user.active}
                                style={{ width: '100%' }}
                                options={[
                                    { value: true, label: 'Hoạt động' },
                                    { value: false, label: 'Đang khoá' },
                                ]}
                            />
                        )}
                    </Form.Item>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Space>
                        <Button htmlType="submit" icon={<CheckOutlined />} style={{ background: '#008965', color: 'white' }}>
                            <b>Xác nhận</b>
                        </Button>
                        <Button onClick={handleBackPage} icon={<CloseOutlined />} style={{ background: '#c93c3c', color: 'white' }}>
                            <b>Đóng</b>
                        </Button>
                    </Space>
                </div>
            </Form>
        </div>
    )
}

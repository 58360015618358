import { Button, Space } from 'antd'
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { useNavigate } from 'react-router-dom'

export default function ButtonActions() {
    const navigate = useNavigate()

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
                <Button htmlType="submit" icon={<CheckOutlined />} style={{ background: '#008965', color: 'white' }}>
                    <b>Xác nhận</b>
                </Button>
                <Button onClick={() => navigate(-1)} icon={<CloseOutlined />} style={{ background: '#c93c3c', color: 'white' }}>
                    <b>Đóng</b>
                </Button>
            </Space>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { getSession } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { Avatar, Card, Col, Row, Tabs, TabsProps, Tag } from "antd";
import { UserOutlined, CheckOutlined } from '@ant-design/icons'
import Overview from "./Overview";
// import ChangePassword from "./ChangePassword";
import UpdateInfo from "./UpdateInfo";
import {apiPostRole} from "../../api";
import {toastError, toastSuccess} from "../../utils/toast";
import ChangePassword from "./ChangePassword";
const ProfilePage: React.FC = () => {
  const [admin, setAdmin] = useState<any>()

  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        const sessionResp = await dispatch(getSession()).unwrap()
        setAdmin(sessionResp.data)
      } catch (error) {
        console.log(error)
        toastError('Có lỗi xảy ra! Vui lòng kiểm tra lại')
      }

    }
    fetchMyAPI()
  }, [dispatch]);

  const itemsTab: TabsProps['items'] = !admin ? [] : [
    {
      key: 'overview',
      label: 'Tổng quan',
      children: <Overview admin={admin} />,
    },
    {
      key: 'update-info',
      label: 'Cập nhật thông tin',
      children: <UpdateInfo admin={admin} />,
    },
    {
      key: 'change-password',
      label: 'Đổi mật khẩu',
      children: <ChangePassword admin={admin} />
    },
  ];

  return (
    <div className='profile-container'>
      <Card>
        {admin && (
          <Row justify='start'>
            <Col lg={8}>
              <div style={{ textAlign: 'center', margin: 12 }}>
                <Avatar size='large' icon={<UserOutlined />} />
                <h2>{`${admin.last_name} ${admin.first_name}`}</h2>
                <Tag icon={<CheckOutlined />} color='green'>
                  Hoạt động
                </Tag>
              </div>
            </Col>
            <Col lg={16}>
              <div style={{ maxWidth: 500 }}>
                <Tabs defaultActiveKey="overview" items={itemsTab} />
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default ProfilePage;

import GroupCheckbox, {CheckboxEx} from './GroupCheckbox'

export default function ListGroupCheckbox({ groupPermissions, selectedRoles, setSelectedRoles, selectedPermissions, setSelectedPermissions}: any) {
    const handleChange = (role: string) => {
        const updatedSelectedPermissions = { ...selectedPermissions };
        if (selectedRoles.includes(role)) {
            // Remove role from selectedRoles
            setSelectedRoles(selectedRoles.filter((r: string) => r !== role));
            // Remove all permissions of that role
            delete updatedSelectedPermissions[role];
        } else {
            // Add role to selectedRoles
            setSelectedRoles([...selectedRoles, role]);
            // Add all permissions of that role
            updatedSelectedPermissions[role] = [...groupPermissions[role]];
        }
        setSelectedPermissions(updatedSelectedPermissions);
    }

    return (
        <>
          {Object.keys(groupPermissions).map(key => 
            <div key={key} style={{ marginBottom: 20 }}>
                <CheckboxEx
                    name={groupPermissions[key].name}
                    data={groupPermissions[key].permissions}
                    role={key}
                    selectedRoles={selectedRoles}
                    setValue={handleChange} />
            </div>
        )}  
        </>
    )
}

export function ListCheckbox({ groupPermissions, selectedRoles, setSelectedRoles, selectedPermissions, setSelectedPermissions}: any) {
    const handleChange = (role: string) => {
        const updatedSelectedPermissions = { ...selectedPermissions };
        if (selectedRoles.includes(role)) {
            // Remove role from selectedRoles
            setSelectedRoles(selectedRoles.filter((r: string) => r !== role));
            // Remove all permissions of that role
            delete updatedSelectedPermissions[role];
        } else {
            // Add role to selectedRoles
            setSelectedRoles([...selectedRoles, role]);
            // Add all permissions of that role
            updatedSelectedPermissions[role] = [...groupPermissions[role].permissions];
        }
        setSelectedPermissions(updatedSelectedPermissions);
    }

    return (
        <>
          {Object.keys(groupPermissions).map(key => 
            <div key={key} style={{ marginBottom: 20 }}>
                <CheckboxEx
                    name={groupPermissions[key].name}
                    data={groupPermissions[key].permissions}
                    role={key}
                    selectedRoles={selectedRoles}
                    setValue={handleChange} />
            </div>
        )}  
        </>
    )
}
const translate = {
    customers: 'Khách hàng',
    shark: 'Shark',
    votes: 'Bình chọn',
    transactions: "Giao dịch",
    transactionsWithdraw: "Giao dịch rút tiền",
    transactionsDeposit: "Giao dịch nạp tiền",
    copyright: 'Bản quyền © 2024. Mọi quyền được bảo lưu.',
    profile: 'Trang cá nhân',
    logout: 'Đăng xuất',
    hi: 'Chào',
    version: 'Phiên bản',
    username: 'Tên tài khoản',
    requireUsername: 'Vui lòng nhập tên tài khoản!',
    password: 'Mật khẩu',
    requirePassword: 'Vui lòng nhập mật khẩu!',
    login: 'Đăng nhập',
    addShark: 'Thêm mới Shark',
    sharkName: 'Họ và tên Shark',
    votedAt: 'Thời gian bình chọn',
    fullName: 'Họ và tên',
    requireFullName: 'Vui lòng nhập họ và tên!',
    title: 'Tiêu đề',
    requireTitle: 'Vui lòng nhập tiêu đề!',
    quote: 'Trích dẫn',
    requireQuote: 'Vui lòng nhập trích dẫn!',
    voted: 'Số phiếu',
    desc: 'Mô tả',
    requireDesc: 'Vui lòng nhập mô tả!',
    add: 'Thêm',
    email: 'Email',
    country: 'Quốc gia',
    mobile: 'Số điện thoại',
    address: 'Địa chỉ',
    active: 'Đang hoạt động',
    inactive: 'Không hoạt động',
    refCode: 'Mã Ref',
    balance: 'Số dư',
    createAt: 'Ngày tham gia',
    action: 'Chức năng',
    topUp: 'Nạp tiền',
    withdraw: 'Rút tiền',
    topUpWithdraw: 'Nạp / Rút tiền',
    requireBalance: 'Số tiền phải lớn hơn 0',
    admin: 'Quản trị viên',
}

export default translate
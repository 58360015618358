import React, { useState } from "react";
import { Layout, Menu, Row } from "antd";
import {
  TeamOutlined,
  CrownOutlined,
  HeartOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  TransactionOutlined,
  BankOutlined,
  UsergroupAddOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import LayoutHeader from "./header";
import LayoutFooter from "./footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import { useMatchedRoute } from "../../routers";
import {
  ADMIN,
  BANKS,
  CUSTOMERS, ROLES,
  SHARKS, TRANSACTIONS,TRANSACTIONS_WITHDRAW,TRANSACTIONS_DEPOSIT,
  VOTES,
} from "../../assets/constants/route_path";
import { getDefaultAuthenticatedRoute } from "../../utils/app-utils";
import IdleSessionTimeout from "./IdleSessionTimeout";
import BreadcrumbItem from "../../components/breadcrumb";
import translate from "../../constants/translate";
import PermUtils from "../../utils/perm-utils";

const { Sider, Content } = Layout;

type MenuItem = {
  key: any;
  label: string | React.ReactNode;
  link?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: string[];
};

const buildMenuItem = ({
  key,
  label,
  link,
  icon,
  children,
  allowedfunctionids,
}: {
  label: string | React.ReactNode;
  key: string;
  link: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  allowedfunctionids?: string[];
}): MenuItem => {
  const item = {
    key,
    label,
    link,
    icon,
    children,
    allowedfunctionids,
  } as MenuItem;
  const hasChildren = children && children.length > 0;

  // Leaf item
  if (link && !hasChildren) {
    item.label = <Link to={link}>{label}</Link>;
  }

  return item;
};

const allItems: MenuItem[] = [
  // buildMenuItem({
  //   key: "Dashboard",
  //   label: "Dashboard",
  //   link: '/dashboard',
  //   icon: <AreaChartOutlined />,
  //   allowedfunctionids: [],
  // }),
  // buildMenuItem({
  //   key: "User",
  //   label: "User",
  //   link: USER,
  //   icon: <UserOutlined />,
  //   allowedfunctionids: [],
  // }),
  buildMenuItem({
    key: "Customers",
    label: translate.customers,
    link: CUSTOMERS,
    icon: <TeamOutlined />,
    allowedfunctionids: [PermUtils.canGetListCustomerAdminResource],
  }),
  buildMenuItem({
    key: "Sharks",
    label: translate.shark,
    link: SHARKS,
    icon: <CrownOutlined />,
    allowedfunctionids: [PermUtils.canGetListSharkResource],
  }),
  buildMenuItem({
    key: "Banks",
    label: "Tài khoản ngân hàng",
    link: BANKS,
    icon: <BankOutlined />,
    allowedfunctionids: [PermUtils.canGetListBankCustomerResource],
  }),
  buildMenuItem({
    key: "Votes",
    label: translate.votes,
    link: VOTES,
    icon: <HeartOutlined />,
    allowedfunctionids: [PermUtils.canGetListVoteResource],
  }),
  buildMenuItem({
    key: "Transactions-Withdraw",
    label: translate.transactionsWithdraw,
    link: TRANSACTIONS_WITHDRAW,
    icon: <TransactionOutlined />,
    // allowedfunctionids: [PermUtils.canGetLisTransactionAdminResource],
    allowedfunctionids: [PermUtils.canGetListWithdrawTransactionResource],
  }),
  buildMenuItem({
    key: "Transactions-Deposit",
    label: translate.transactionsDeposit,
    link: TRANSACTIONS_DEPOSIT,
    icon: <TransactionOutlined />,
    // allowedfunctionids: [PermUtils.canGetLisTransactionAdminResource],
    allowedfunctionids: [PermUtils.canGetListDepositTransactionResource],
  }),
  buildMenuItem({
    key: "Admin",
    label: translate.admin,
    link: ADMIN,
    icon: <UsergroupAddOutlined />,
    allowedfunctionids: [PermUtils.canQueryListUserResource],
  }),
  buildMenuItem({
    key: "Roles",
    label: "Phân quyền",
    link: ROLES ,
    icon: <ApartmentOutlined />,
    allowedfunctionids: [PermUtils.canCreateRoleResource],
  }),
];

const isAuthorizedItem = (item: MenuItem, userFnIDs: string[]): boolean => {
  if (item?.allowedfunctionids && item?.allowedfunctionids?.length > 0) {
    return item?.allowedfunctionids?.some((id) => userFnIDs?.includes(id));
  }

  return true;
};

const authorizeMenuItems = (
  items: MenuItem[] = [],
  userFnIDs: string[] = [],
): MenuItem[] => {
  // Filter active items for origin array
  items = items?.filter((item) => isAuthorizedItem(item, userFnIDs));

  // Filter active items for nested items as children
  items?.forEach((item) => {
    if (item?.children && item?.children?.length > 0) {
      item.children = authorizeMenuItems(item.children, userFnIDs);
    }
  });

  return items;
};

const listAllActiveItems = (
  items: MenuItem[],
  currentPath: string | undefined,
): MenuItem[] => {
  const activeItems = items.filter(
    (item) => item.link && currentPath?.startsWith(item.link),
  );

  const childActiveItems = activeItems
    .map((item) => {
      if (item.children && item.children.length > 0) {
        return listAllActiveItems(item.children, currentPath);
      }

      return [];
    })
    .flat();

  return [...activeItems, ...childActiveItems];
};

const MainLayout = ({ children }: any) => {
  const authState = useSelector(authSelector);
  const { account } = authState;
  const navigate = useNavigate();
  const currentRoutePath = useMatchedRoute();
  const [collapsed, setCollapsed] = useState(false);
  // Avoid execute these functions multiple times
  const authorizedItems = authorizeMenuItems(allItems, account.listPermission);

  // Always expand all items
  const openedKeys = authorizedItems.map((item) => item.key);

  const activeItems = listAllActiveItems(authorizedItems, currentRoutePath);

  const activeItemLength = activeItems.length;
  const activeItemKeys = activeItems.map((item) => item.key);
  const selectedKey =
    activeItemLength === 0 ? null : activeItemKeys[activeItemLength - 1];

  const getSiderFooter = () => {
    return (
      <Row
        justify='space-between'
        align='middle'
        style={{ padding: "0 1rem " }}>
        <div style={{ fontSize: 14 }}>
          <SettingOutlined /> {translate.version} {process.env.REACT_APP_VERSION}
        </div>
        <div>
          <LeftOutlined />
        </div>
      </Row>
    );
  };
  return (
    <Layout hasSider>
      <Sider
        breakpoint='md'
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={!collapsed ? getSiderFooter() : <RightOutlined />}>
        <Row
          justify='center'
          style={{ backgroundColor: "white" }}
          onClick={() => navigate(getDefaultAuthenticatedRoute(account))}>
          {collapsed ? (
            <img
              src='/images/shark_tank.png'
              alt='Shark stank'
              style={{
                width: 75,
                height: 55,
                marginBottom: 8,
                cursor: "pointer",
              }}
            />
          ) : (
            <img
              src='/images/shark_tank.png'
              alt='Shark stank'
              style={{
                width: 250,
                height: 50,
                marginTop: 10,
                marginBottom: 3,
                cursor: "pointer",
              }}
            />
          )}
        </Row>
        <Menu
          theme='dark'
          mode='inline'
          defaultOpenKeys={openedKeys}
          items={authorizedItems}
          selectedKeys={selectedKey}
        />
      </Sider>
      <Layout
        className='site-layout'
        style={{
          marginLeft: collapsed ? 80 : 250,
          transition: "margin-left 0.2s",
        }}>
        <LayoutHeader profile={account} collapsed={collapsed} />
        <div
          style={{
            padding: 12,
            marginTop: 64,
            paddingBottom: 0,
          }}>
          <BreadcrumbItem />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 100px)",
          }}>
          <Content
            style={{
              padding: 12,
              flexGrow: 1,
            }}>
            <div
              style={{
                minHeight: "100%",
                backgroundColor: "white",
              }}>
              {children}
            </div>
          </Content>
          <LayoutFooter />
        </div>
      </Layout>
      <IdleSessionTimeout account={authState.account} />
    </Layout>
  );
};

export default MainLayout;

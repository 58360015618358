import {
  createBrowserRouter,
  matchRoutes,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  NotFound,
  LoginPage,
  AuthPage,
  ProfilePage,
  Home,
  SharkPage,
  VotePage,
  CustomerPage,
  TransactionWithdrawPage,
  TransactionDepositPage,
  BankPage,
  AdminPage,
  AdminEditPage, RolePage, RoleCreatePage, RoleEditPage,
} from "../pages";
import {
  AUTH_LOGIN,
  CUSTOMERS,
  HOME,
  PROFILE,
  SHARKS,
  VOTES,
  TRANSACTIONS_WITHDRAW, 
  TRANSACTIONS_DEPOSIT,
  BANKS, ADMIN, ADMIN_EDIT, ROLES, ROLE_EDIT, ROLE_CREATE,
} from "../assets/constants/route_path";
import Layout from "../layout";
import PrivateRoute from "../components/private-route";
import { DashboardLayout } from "../pages/dashbooard";
import PermUtils from "../utils/perm-utils";

export const routes = [
  {
    path: "/auth",
    element: <AuthPage />,
    children: [
      {
        path: AUTH_LOGIN,
        element: <LoginPage />,
      },
    ],
  },

  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: HOME,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        ),
      },
      {
        path: "/user",
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: CUSTOMERS,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canGetListCustomerAdminResource]}>
            <CustomerPage />
          </PrivateRoute>
        ),
      },
      {
        path: SHARKS,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canGetListSharkResource]}>
            <SharkPage />
          </PrivateRoute>
        ),
      },
      {
        path: VOTES,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canGetListVoteResource]}>
            <VotePage />
          </PrivateRoute>
        ),
      },
      {
        path: TRANSACTIONS_DEPOSIT,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canGetListDepositTransactionResource]}>
            <TransactionDepositPage />
          </PrivateRoute>
        ),
      },
      {
        path: TRANSACTIONS_WITHDRAW,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canGetListWithdrawTransactionResource]}>
            <TransactionWithdrawPage />
          </PrivateRoute>
        ),
      },
      {
        path: BANKS,
        element: (
          <PrivateRoute
              hasAnyFunctions={[PermUtils.canGetListBankCustomerResource]}>
            <BankPage />
          </PrivateRoute>
        ),
      },
      {
        path: ADMIN,
        element: (
          <PrivateRoute
              hasAnyFunctions={[PermUtils.canQueryListUserResource]}>
            <AdminPage />
          </PrivateRoute>
        ),
      },
      {
        path: ADMIN_EDIT,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canUpdateUserAdminResource]}>
            <AdminEditPage/>
          </PrivateRoute>
        ),
      },
      {
        path: ROLES,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canListRoleResource]}>
            <RolePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROLE_CREATE,
        element: (
          <PrivateRoute
              hasAnyFunctions={[PermUtils.canCreateRoleResource]}>
            <RoleCreatePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROLE_EDIT,
        element: (
          <PrivateRoute hasAnyFunctions={[PermUtils.canUpdateRoleResource]}>
            <RoleEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE,
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const useMatchedRoute = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (!matchedRoutes || matchedRoutes.length === 0) {
    return undefined;
  }

  // Sort by accuracy desc
  matchedRoutes.reverse();

  for (let matchedRoute of matchedRoutes) {
    const matchedRoutePath = matchedRoute.route.path;
    // index route will not contain path attr -> get next route (parent route)
    if (matchedRoutePath) {
      return matchedRoutePath;
    }
  }

  return undefined;
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);

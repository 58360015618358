import axios from "axios"
import { AUTH_TOKEN_KEY } from "../config/constants";

const instance = axios.create({ baseURL: process.env.REACT_APP_SERVER_API_URL_2 })
instance.interceptors.request.use(function (config) {
    config.headers.Accept = "application/json"
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// export const apiChangeBalance = (data: any) => instance.post(`/v1/admins/change-balance`, data);
export const apiDeposit = (data: any) => instance.post(`/v1/admins/deposit`, data);
export const apiWithdraw = (data: any) => instance.post(`/v1/admins/withdraw`, data);
export const apiGetCustomers = (params: any) => instance.get(`/v1/admins/customers`, { params });
export const apiGetSharks = (params: any) => instance.get(`/v1/sharks`, { params });

// export const apiGetTransactions = (params: any) => instance.get(`/v1/admins/transactions`, { params });
export const apiGetDepositTransactions = (params: any) => instance.get(`/v1/admins/transactions/deposit`, { params });
export const apiGetWithdrawTransactions = (params: any) => instance.get(`/v1/admins/transactions/withdraw`, { params });
export const apiAddShark = (data: any) => instance.post(`/v1/sharks`, data);
export const apiGetVotes = (params: any) => instance.get(`/v1/votes`, { params });

export const apiGetBanks = (params: any) => instance.get(`/v1/customers/banks`, { params });

export const apiTxState = (data: any) => instance.put(`/v1/admins/tx-state`, data);

export const getListBankPublic = () => instance.get(`https://api.vietqr.io/v2/banks`)
export const apiUpdateBank = (id: Number, data: any) => instance.put(`/v1/admins/banks/${id}`, data)
export const apiDeleteBank = (id: Number) => instance.delete(`/v1/admins/banks/${id}`)

export const apiAdminUpdateUser = (data: any) => instance.post(`/v1/admins/update-user`, data)
export const apiAdminActiveUser = (userId: string, active: boolean) => instance.post(`/v1/admins/active-user/${userId}`, {active})

export default instance